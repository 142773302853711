<template>
  <div class="pure-g container-sm" :class="{ 'empty-block-wrapper': isEmpty }">
    <div :class="cardClass" v-for="e in elements" :key="e.id">
      <router-link :to="{ name: to, params: { uid: e.id } }" :custom="true" v-slot="{href, navigate}">
        <a class="card-wrp" :class="'card-wrp-' + display" :href="href" @click="onProfileClick($event, navigate, e.id)">
          <component :is="cardComponent" :uid="e.id" :date="e.date" :text="e.text" :isActive="e.id == activeId">
            <template #top-right>
              <slot name="profile-top-right" :elem="e"></slot>
            </template>

            <template #bottom-left>
              <slot name="profile-bottom-left" :elem="e"></slot>
            </template>

            <template #bottom-right>
              <slot name="profile-bottom-right" :elem="e"></slot>
            </template>

            <template #text>
              <slot name="text" :elem="e"></slot>
            </template>
          </component>
        </a>
      </router-link>
    </div>
    <div class="pure-u-1 loading-block" v-if="loading">
      <VueElementLoading :active="true" size="40" />
    </div>
    <div class="pure-u-1 empty" v-if="isEmpty">
      <slot name="empty">
        <p class="txt-c">
          Ничего не найдено...
        </p>
      </slot>
    </div>
    <ScrollTop />
  </div>
</template>

<script>
import '@/assets/css/empty-list.css'

import ProfileCard from '@/components/ProfileCard'
import ProfileCardHorizontal from '@/components/ProfileCardHorizontal'
import ScrollTop from '@/components/ScrollTop'

export default {
  name: 'UsersList',
  events: ["ending", "profileClick"],
  components: {
    ProfileCard, ProfileCardHorizontal, ScrollTop
  },
  props: {
    // список загруженных юзеров или объектов (лайк/гость/...)
    // объект должен содержать id и date
    "elements": {
      type: Array,
      required: true
    },
    "loading": {
      type: Boolean,
      default: false
    },
    "activeId": {
      type: String,
      required: false,
    },
    "display": {
      type: String,
      required: false,
      default: "grid" // or list
    },
    "to": {
      type: String,
      required: false,
      default: "Profile"
    }
  },
  data() {
    return {
      scrollElement: null,
    }
  },
  computed: {
    isEmpty() {
      return this.elements.length == 0 && !this.loading
    },
    cardComponent() {
      return this.display == 'grid' ? ProfileCard : ProfileCardHorizontal
    },
    cardClass() {
      return this.display == 'grid' ? 'pure-u-1-2 pure-u-md-1-3 pure-u-lg-1-4' : 'pure-u-1'
    }
  },
  mounted() {
    this.scrollElement = document.querySelector('#app > .kvisto-app')
    this.scrollElement.addEventListener("scroll", this.handleScroll)
    this.handleScroll()
  },
  unmounted() {
    this.scrollElement.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      let body = this.scrollElement.children[0],
        p = body.parentNode,
        bottomOffset = (p.scrollHeight - p.clientHeight) - (body.scrollTop || p.scrollTop)
      if (!this.loading && bottomOffset < 1000) {
        this.$emit('ending')
      }
    },
    onProfileClick(e, navigate, uid) {
      // override stopPropagation function to see if it was stopped by parent component or not
      const stopPropagationFunc =  e.stopPropagation
      e.isPropagationStopped = false  // marker
      e.stopPropagation = function() {
          this.isPropagationStopped = true  // if parent component stops propagation - set marker
          // call original function
          stopPropagationFunc.apply(this, arguments)
      }
      this.$emit('profileClick', e, uid)
      if (!e.isPropagationStopped) {
        navigate(e)
      }
    }
  }
}
</script>


<style scoped>
.loading-block {
  min-height: 5rem;
}

.card-wrp, .card-wrp:active, .card-wrp:visited {
  text-decoration: none;
}
.card-wrp-grid {
  margin-bottom: 1rem;
}
</style>