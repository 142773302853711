<template>
  <header class="pure-g">
    <div class="pure-u-1-5 controls">
      <button class="btn btn-back" type="button" @click="goBack" v-if="!disableBack && backUrlValue"><i class="icon-angle-left"></i></button>
      <slot name="controls-left"></slot>
    </div>
    <div class="pure-u-3-5 title">
      <img alt="Kvisto" src="@/assets/logo.svg" ref="logoBlock" v-if="title === null" />
      <span v-else>{{ title }}</span>
    </div>
    <div class="pure-u-1-5 txt-r controls">
      <slot name="controls-right"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MobileHeader',
  props: {
    "disableBack": {
      type: Boolean,
      default: false
    },
    "defaultBackUrl": {
      type: String,
      default: null
    },
    "title": {
      type: String,
      default: null
    }
  },
  data() {
    return {
      backUrlValue: null
    }
  },
  beforeMount() {
    if (this.$router.options.history.state.back) {
      this.backUrlValue = this.$router.options.history.state.back
    } else if (this.defaultBackUrl) {
      this.backUrlValue = this.defaultBackUrl
    }
  },
  methods: {
    goBack() {
      // если backUrl совпадает с предыдущей страницей, то идем туда
      if (this.backUrlValue && this.$router.options.history.state.back === this.backUrlValue) {
        this.$router.go(-1)
      } else if (this.backUrlValue) {
        this.$router.push(this.backUrlValue)
      }
    }
  }
}
</script>


<style scoped>
header {
  height: var(--top-bar-height);
  z-index: 1;
}
header > .controls,
header > .title {
  height: 100%;
  display: flex;
  align-items: center;
}

header > .controls .btn.btn-back i {
  position: relative;
  top: 2px;
}

header > .title {
  justify-content: center;
}

header > .controls:last-of-type {
  justify-content: flex-end;
}

.title img {
  max-width: 6rem;
}
</style>
