<template>
  <div class="pure-g form-registration">
    <img class="flying-heart" src="@/assets/img/welcome/flying-heart.svg" :style="{'min-height': initialFormHeight}"/>

    <img class="bottom-heart bottom-heart-1" src="@/assets/img/welcome/heart-1.svg" :style="{'min-height': initialFormHeight}"/>
    <img class="bottom-heart bottom-heart-2" src="@/assets/img/welcome/heart-2.svg" :style="{'min-height': initialFormHeight}"/>
    <img class="bottom-heart bottom-heart-3" src="@/assets/img/welcome/heart-3.svg" :style="{'min-height': initialFormHeight}"/>

    <img class="bottom-heart ellipse-red" src="@/assets/img/welcome/ellipse-red.svg" :style="{'min-height': initialFormHeight}"/>
    <img class="bottom-heart ellipse-blue" src="@/assets/img/welcome/ellipse-blue.svg" :style="{'min-height': initialFormHeight}"/>

    <h4 class="pure-u-2-3">
      Регистрация
    </h4>
    <div class="pure-u-1-3 txt-r steps">
      <span v-for="i in 3" :key="'step' + i" class="step" v-bind:class="{
        'done': i < step,
        'active': step == i
      }">{{ i }}</span>
    </div>

    <div class="step-contents">
      <div class="step" v-show="step==1">
        <FormRegistrationStep1
          :showErrors="showErrors"
          v-model:gender="user.gender"
          v-model:looking_for="user.looking_for"
          v-model:city_id="user.city_id"/>
      </div>

      <div class="step" v-show="step==2">
        <FormRegistrationStep2
          :showErrors="showErrors"
          v-model:name="user.name"
          v-model:birthday="user.birthday"/>
      </div>

      <div class="step" v-show="step==3">
        <FormRegistrationStep3
          :showErrors="showErrors"
          v-model:phone="user.phone"
          v-model:password="user.password"
          v-show="stage=='phone'"
          :minLength="passwordMinLength"/>
        
        <CodeInput class="pure-u-1" v-if="stage == 'code'"
          v-model="code"
          :phone="user.phone"
          :error="(!code || errData.code) && showErrors"
          @back="stepBack"
          @repeat="onRepeat()" />

        <VueElementLoading :active="loading"/>
      </div>
    </div>

    <div class="pure-u-1 padding-b txt-c text-red" v-if="showErrors && errCode != 0">
      {{ errMessage }}
    </div>

    <div class="pure-g container-sm">
      <div class="pure-u-md-1-2" :class="{'pure-u-1': step == 1, 'pure-u-1-2': step > 1}">
        <button class="btn btn-lg btn-grey full-width margin-t" type="button" @click="stepBack" v-show="step > 1">
          Назад
        </button>
      </div>
      <div class="pure-u-md-1-2 txt-r" :class="{'pure-u-1': step == 1, 'pure-u-1-2': step > 1}">
        <button class="btn btn-lg btn-red full-width margin-t" type="button" @click="stepNext">Далее</button>
      </div>
    </div>

    <div class="pure-g container-sm" v-show="step == 1">
      <div class="pure-u-1 backlined margin-v">или</div>
      <div class="pure-u-1-2 margin-half-b">
        <button type="button" class="btn btn-border btn-social btn-vk prefixed full-width">
          <i class="icon-vk-solid"></i> <span>Войти через VK</span>
        </button>
      </div>
      <div class="pure-u-1-2 margin-half-b">
        <VDropdown>
          <button type="button" class="btn btn-border btn-social btn-other prefixed full-width">
            <i class="icon-other"></i> <span>Другой способ</span>
          </button>

          <template #popper="{ hide }">
            <button class="btn btn-context-menu btn-google prefixed" type="button" @click="hide()">
              <i class="icon-google"></i> Войти через Google
            </button>
            <button class="btn btn-context-menu btn-yandex prefixed" type="button" @click="hide()">
              <i class="icon-yandex"></i> Войти через Yandex
            </button>
            <button class="btn btn-context-menu btn-mailru prefixed" type="button" @click="hide()">
              <i class="icon-mailru"></i> Войти через Mail.ru
            </button>
            <button class="btn btn-context-menu btn-odnoklassniki prefixed" type="button" @click="hide()">
              <i class="icon-odnoklassniki"></i> Войти через OK
            </button>
          </template>
        </VDropdown>
        
      </div>
      <div class="pure-u-1 padding-half-v txt-c have-account">
        Уже есть аккаунт? <a class="text-red line-dashed margin-half-h" href="javascript:" @click="login">Войти</a>
      </div>
    </div>

  </div>
</template>

<script>
import {apiCall, apiErrCodes, SERVER_ADDRESSES} from "@/utils/api";
import {pushModal} from "jenesius-vue-modal"


import FormLogin from '@/components/modals/FormLogin.vue'
import FormRegistrationStep1 from '@/components/registration/FormRegistrationStep1.vue'
import FormRegistrationStep2 from '@/components/registration/FormRegistrationStep2.vue'
import FormRegistrationStep3 from '@/components/registration/FormRegistrationStep3.vue'
import WelcomeModal from '@/components/modals/WelcomeModal.vue'

import CodeInput from '@/components/inputs/CodeInput.vue'


export default {
  name: 'FormRegistration',
  components: {
    FormRegistrationStep1,
    FormRegistrationStep2,
    FormRegistrationStep3,
    CodeInput
  },
  data: function() {
    return {
      step: 1,
      showErrors: false,
      errCode: 0,
      errData: {},
      loading: false,
      passwordMinLength: 6,
      stage: "phone",
      code: "",
      user: {
        gender: "male",
        looking_for: "female",
        city_id: null,

        name: "",
        birthday: "",

        phone: "",

        password: ""
      },
    }
  },
  computed: {
    errMessage() {
      if (this.errCode == apiErrCodes.DUPLICATE_ERROR) {
        return "Указанный телефонный номер уже используется"
      } else if (this.errCode == apiErrCodes.INVALID_VALUE_ERROR && this.errData.code) {
        return "Введен неверный код подтверждения"
      }
      return "Неизвестная ошибка. Попробуйте позже или обратитесь к технической поддержке."
    },
  },
  methods: {
    clearErrors() {
      this.showErrors = false
      this.errCode = 0
      this.errData = {}
    },
    stepNext() {
      this.clearErrors()

      if (this.step==1 && (!this.user.gender || !this.user.looking_for || !this.user.city_id)) {
        this.showErrors = true
        return
      }
      if (this.step==2 && (!this.user.name || !this.user.birthday)) {
        this.showErrors = true
        return
      }

      if (this.step==3 && (!this.user.password || this.user.password.length < this.passwordMinLength || !this.user.phone)) {
        this.showErrors = true
        return
      } else if (this.step==3 && this.stage == 'code' && !this.code) {
        this.showErrors = true
        this.errData = {code: "error"}
        return
      }

      else if (this.step == 3) {
        this.doRegistration()
        return
      }
      this.step++
    },
    stepBack() {
      this.clearErrors()
      this.showErrors = false
      if (this.stage == 'code') {
        this.stage = 'phone'
        this.code = ""
      } else {
        this.step--
      }
    },

    onRepeat() {
      this.stage = 'phone'
      this.$nextTick(() => {
        this.doRegistration()
      })
    },
    doRegistration() {
      this.loading = true
      apiCall({ url: SERVER_ADDRESSES.auth + "register", method: "POST", data: {
        phone: this.user.phone,
        password: this.user.password,
        code: this.code,
        profile: {
          gender: this.user.gender,
          looking_for: this.user.looking_for,
          city_id: this.user.city_id,
          name: this.user.name,
          birthday: this.user.birthday,
          referrer: localStorage.getItem("ref") || "",
        }
      }})
        .then(resp => {
          this.loading = false
          if (!resp.success) {
            this.errCode = resp.err_code ?? -1
            this.errData = resp.err_data ?? {}
            this.showErrors = true
            return
          }
          if (resp.success && !this.code) {
            this.stage = 'code'
            return
          } else if (resp.success) {
            this.authenticate()
          }
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          this.errCode = e.err_code ?? -1
          this.errData = e.err_data ?? {}
          this.showErrors = true
        });
    },
    authenticate() {
      this.$store.dispatch("AUTH_REQUEST", {
        "grant_type": "password",
        "username": this.user.phone,
        "password": this.user.password
      }).then(() => {
        this.$router.push('/users')
        setTimeout(() => {
          pushModal(WelcomeModal)
        }, 500)
      }).catch((e) => {
        this.loginErrCode = e.err_code
        this.showErrors = true
      })
    },
    login() {
      pushModal(FormLogin)
    }
  }
}
</script>

<style>
.form-registration .form-field {
  margin-bottom: .5em;
}

/* xs */
@media screen and (max-width: 35.45em) {
  .form-registration .form-field {
    margin-bottom: -.5rem;
  }
}

.form-registration label {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  font-weight: 600;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-registration .form-field label,
.form-registration .form-field .hint {
  display: inline-block;
  margin: .5em;
}
</style>

<style scoped>
.form-registration {
  position: relative;
  display: block;
  background: #FFF;
  box-shadow: 0px 1.25rem 6.5rem rgba(176,176,176,.2);
  border-radius: 1.25rem;
  padding: 1.8rem 1.5rem;
  margin: 1rem 0;
}

.flying-heart {
  position: absolute;
  left: -1%;
  top: -1rem;
  width: 20%;
  max-width: 4rem;
}

.bottom-heart {
  position: absolute;
}
.bottom-heart-1 {
  bottom: -1rem;
  right: 1.5rem;
}
.bottom-heart-2 {
  bottom: -3rem;
  right: 0rem;
}
.bottom-heart-3 {
  bottom: -4rem;
  right: 3rem;
}
.ellipse-red {
  left: 0;
  bottom: -1rem;
  opacity: .7;
}
.ellipse-blue {
  left: 2rem;
  bottom: -4rem;
}

.backlined {
  color: #969696;
}
.backlined:before,
.backlined:after {
  border-color: #F1F1F1;
}

h4 {
  margin: 0 0 .5rem;
  font-weight: 700;
  color: #222020;
  font-size: 1.5rem;
}

.steps {
  margin-top: .2em;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.steps .step {
  display: inline-flex;
  justify-content: center;
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: 600;
  margin-left: .5rem;
  border: 1.2px solid #DADADA;
  border-radius: 50%;
  font-size: .8rem;
  color: #222020;
  aspect-ratio: 1;

  transition: all .2s;
}
.steps .step.done {
  background: #FBC1C3;
  border-color: #FBC1C3;
  color: #FFF;
}
.steps .step.active {
  color: #FFF;
  border-color: #FF426F;
  background-color: #FF426F;
}
.steps .step:first-of-type {
  margin-left: 0;
}

.step-contents .btn {
  font-size: .75em;
  padding-top: .6em;
  padding-bottom: .6em;
}
.step-contents .btn i {
  font-size: 1.2em;
  top: .05em;
}

.have-account {
  font-weight: 500;
}

/* md+ */
@media screen and (min-width: 48em) {
  .form-registration {
    padding: 1.875rem 3.125rem;
  }

  .steps .step {
    margin-left: .8rem;
  }
}

/* xs */
@media screen and (max-width: 35.45em) {
  .btn.btn-social {
    padding-left: .5rem;
    padding-right: .25rem;
    font-size: .8rem;
  }
}
</style>
