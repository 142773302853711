<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <!-- <CustomScrollbar :style="{ height: '100vh' }"> -->
  <div class="kvisto-app" v-if="isAuthenticated" :class="{
    'mobile': isMobileDevice,
    'with-bottom-bar': bottomBarVisible
  }">
    <main class="max-width">
      <GridOffset class="main-content" v-if="!isTouchMobileDevice">
        <div class="pure-g container">
          <aside class="hidden-xs hidden-sm hidden-md pure-u-lg-5-24">
            <AsideMenu v-sticky />
          </aside>
          <div class="pure-u-4-5 central-content" :class="{
            'pure-u-lg-14-24': rightIslandVisible,
          }">
            <router-view class="island" v-slot="{ Component }">
              <KeepAlive include="Near" exclude="Me,Profile,FormSearch,ProfileCard">
                <component :is="Component" />
              </KeepAlive>
            </router-view>
          </div>
          <div class="hidden-xs hidden-sm hidden-md hidden-lg pure-u-xl-5-24">
            <div class="full-width">
              <div class="fixed island" v-sticky>
                <TopUsers class="padding" />
              </div>
            </div>
          </div>
        </div>
      </GridOffset>

      <div class="main-content" v-else>
          <div class="island">
            <router-view v-slot="{ Component }">
              <KeepAlive include="Near" exclude="Me,Profile,FormSearch,ProfileCard">
                <component :is="Component" />
              </KeepAlive>
            </router-view>
          </div>
          <BottomNavBar />
      </div>
    </main>
  </div>
  <router-view v-else />

  <WidgetContainerModal />
  <!-- </CustomScrollbar> -->
</template>

<script>
import { container } from "jenesius-vue-modal";

import GridOffset from '@/components/GridOffset.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import TopUsers from '@/components/TopUsers.vue'

import BottomNavBar from '@/components/mobile/BottomNavBar.vue'

import { mapGetters } from "vuex"
import { SERVER_ADDRESSES, getToken } from "@/utils/api"

import mixinDevice from "@/mixins/device.js"

export default {
  mixins: [mixinDevice],
  components: {
    WidgetContainerModal: container,
    GridOffset: GridOffset,
    AsideMenu: AsideMenu,
    TopUsers: TopUsers,
    BottomNavBar: BottomNavBar
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isScrollDisabled", "bottomBarVisible"]),
    rightIslandVisible() {
      return !(this.isTouchMobileDevice && this.$route.meta.disableRightAside);
    }
  },
  watch: {
    isAuthenticated: function () {
      this.isAuthenticated ? this.wsConnect() : this.wsDisconnect()
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.wsConnect()
    }
  },
  methods: {
    wsConnect() {
      this.$connect(SERVER_ADDRESSES.ws + '?token=' + getToken())
    },
    wsDisconnect() {
      this.$disconnect()
    }
  }
}
</script>

<style scoped>
.kvisto-app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100vh;
  background-color: #F6F6F6;
}
.kvisto-app.mobile {
  background-color: #FFF;
  overflow: hidden;
}

aside {
  min-height: 100vh;
}

main {
  min-height: 100vh;
}

.main-content,
.main-content>.pure-u-22-24,
.main-content>.pure-u-22-24>.container,
.main-content>.pure-u-22-24>.container>div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  min-height: 100%;
}

.mobile .main-content {
  display: flex;
  flex-direction: column;
}

.mobile .main-content>.island{
  height: 100vh;
  overflow-y: auto;
}

.mobile.with-bottom-bar .main-content>.island{
  height: calc(100vh - var(--bottom-bar-height));
}

.island {
  position: relative;
  margin: 1.8rem 0;
  background-color: #FFF;
  box-shadow: 0px .25rem 1.8rem rgba(0, 0, 0, 0.1);
  border-radius: .9rem;
  flex: 1 1 auto;
  overflow: hidden;
}

.mobile .island {
  box-shadow: none;
  margin: 0;
  border-radius: 0;
}

.fixed {
  max-height: calc(100vh - 3.6rem);
}
</style>