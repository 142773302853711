<template>
  <span :class="{ 'active': isActive }" class="status" v-if="lastMsg && lastMsg.from_id == myId">
    <i v-if="isPartnerRead" class="icon-check-double"></i>
    <i v-else-if="lastMsg.date" class="icon-check"></i>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    "lastMsg": {
      required: false,
      default: null
    },
    "partnerReadUntil": {
      required: false,
      default: null
    },
    "isActive": {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(["myId"]),
    isPartnerRead() {
      if (!this.lastMsg || !this.lastMsg.date || !this.partnerReadUntil) {
        return false
      }
      return new Date(this.partnerReadUntil) >= new Date(this.lastMsg.date)
    }
  }
}
</script>

<style scoped>
.status {
  font-size: .8rem;
  color: #359EFF;
  margin: 0 .5rem;
}

.status.active {
  color: #FFF;
}

.status .icon-check {
  font-size: .8em;
}
</style>