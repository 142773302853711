<template>
  <div class="pure-g">
    <div class="pure-u-1 relations-list" :class="dialogsBlockClass">
      <div v-if="isMobileDevice">
        <MobileHeader disableBack>
          <template #controls-right>
            <VDropdown placement="bottom-end" v-if="relations.length && isSelectable">
              <button class="btn btn-transparent btn-more"><i class="icon-dots"></i></button>

              <template #popper="{ hide }">
                <button class="btn btn-context-menu" v-if="checkedUids.length != relations.length"
                  @click="selectAll()">Выделить все</button>
                <button class="btn btn-context-menu" v-if="checkedUids.length" @click="deselectAll(); hide()">Отменить
                  выделение</button>

                <button class="btn btn-context-menu" :disabled="!checkedUids.length"
                  @click="removeSelected(); hide()">Удалить
                  выбранных</button>
              </template>
            </VDropdown>
          </template>
        </MobileHeader>

        <div class="padding">
          <VueSelect
            :modelValue="relationType"
            @update:modelValue="onRelationTypeChange($event.value)"
            :options="relationTypeOptions"
            :clearable="false"
            :searchable="false">

            <template #selected-option-container>
              <div class="type-option">
                <i :class="types[relationType].icon"></i>
                {{ types[relationType].title }}
              </div>
            </template>

            <template #option="{ value }">
              <div class="type-option">
                <i :class="types[value].icon"></i>
                {{ types[value].title }}
              </div>
            </template>
          </VueSelect>
      </div>

      </div>
      <template v-else>
        <form method="GET" @submit.prevent class="padding" ref="searchForm" v-if="relationType == 'messages'">
          <div class="prefixed block-vcenter grey-input padding-half-l">
            <i class="icon-search margin-half"></i>
            <input class="grey-input no-padding-l" type="text" placeholder="Поиск..." v-model="searchVal" />
          </div>
        </form>
        <PageTitle :title="title" v-else>
          <template #after>
            <VDropdown placement="bottom-end" v-if="relations.length && isSelectable">
              <button class="btn btn-grey btn-more"><i class="icon-dots"></i></button>

              <template #popper="{ hide }">
                <button class="btn btn-context-menu" v-if="checkedUids.length != relations.length"
                  @click="selectAll()">Выделить все</button>
                <button class="btn btn-context-menu" v-if="checkedUids.length" @click="deselectAll(); hide()">Отменить
                  выделение</button>

                <button class="btn btn-context-menu" :disabled="!checkedUids.length"
                  @click="removeSelected(); hide()">Удалить
                  выбранных</button>
              </template>
            </VDropdown>
          </template>
        </PageTitle>
      </template>

      <UsersList
        :display="usersDisplayType"
        :elements="filteredRelations"
        :loading="isPageLoading"
        :to="isChatVisible || relationType == 'messages' ? 'Relations' : 'Profile'"
        :class="{'padding': !isChatVisible}"
        @ending="loadPage"
        @profileClick="onProfileClick"
        :activeId="uid">

        <template #profile-top-right="{ elem }">
          <span class="checkbox" @click.stop v-if="isSelectable">
            <input :id="'checkbox_' + elem.id" :value="elem.id" type="checkbox" v-model="checkedUids" />
            <label :for="'checkbox_' + elem.id"></label>
          </span>
        </template>

        <template #profile-bottom-left="{ elem }">
          <MessageReadFlag v-if="relationType == 'messages'" :lastMsg="elem.last_msg" :partnerReadUntil="elem.partner_read_until" :isActive="uid == elem.id"/>
        </template>

        <template #profile-bottom-right="{ elem }">
          <span v-if="elem.unread_count" class="unread">{{ elem.unread_count }}</span>
          <span v-else>&nbsp;</span>
        </template>

        <template #text="{ elem }">
          <MessageLastText v-if="relationType == 'messages'" :lastMsg="elem.last_msg" :isActive="uid == elem.id" :uid="elem.id" />
        </template>

        <template #empty>

          <template v-if="relationType == 'likes'">
            <p>
              <img v-if="gender == 'female'" src="@/assets/img/empty/man1.svg" />
              <img v-else src="@/assets/img/empty/woman1.svg" />
            </p>
            <p class="txt-c">
              Те, кто поставил тебе лайк будут здесь.
              Можешь поставить взаимный лайк и сразу образовать пару!
            </p>
          </template>

          <template v-else-if="relationType == 'pairs'">
            <p>
              <img src="@/assets/img/empty/hearts1.svg" />
            </p>
            <p class="txt-c">
              Здесь будут те, кто поставил вам взаимный лайк, а пока тут пусто.
              Как можно больше ставьте лайки в игре и ждите взаимных!
            </p>
          </template>

          <template v-else-if="relationType == 'guests'">
            <p>
              <img src="@/assets/img/empty/couple1.svg" />
            </p>
            <p class="txt-c">
              Здесь будут те, кто смотрел ваш профиль, а пока тут пусто...
            </p>
          </template>

          <template v-else-if="relationType == 'favorites'">
            <p class="top-star" v-if="gender == 'female'">
              <i class="icon-menu-favorites"></i>
              <img src="@/assets/img/empty/man1.svg" />
            </p>
            <p class="top-star" v-else>
              <img src="@/assets/img/empty/woman1.svg" />
              <i class="icon-menu-favorites"></i>
            </p>
            <p class="txt-c">
              Добавляйте кого хотите в избранные, чтобы не потерять. И все они будут здесь!
            </p>
          </template>

          <template v-else-if="relationType == 'messages'">
            <p>
              <img src="@/assets/img/empty/clouds-and-hearts.svg" />
            </p>
            <p>
              Здесь пока пусто. Начните общаться прямо сейчас!
              Ищите людей рядом с вами и заводите новые знакомства!
            </p>
          </template>

          <router-link :to="{ name: 'Near' }" class="btn btn-red">
            Поиск
          </router-link>
        </template>

      </UsersList>
    </div>

    
    <div class="pure-u-1 chat-block" :class="chatBlockClass" v-show="uid || isChatVisible">
      <ChatHeader :uid="uid" ref="chatHeader" @delDialog="onDelDialog" />
      <hr class="no-margin" />
      <MessagesList :uid="uid" :dialog="activeDialog" :empty="!relations.length" :height="messagesListHeight" class="messages"
        ref="msgList" @repeatMessage="repeatMessage" />
      <ChatForm :uid="uid" :draft="uid in drafts ? drafts[uid] : ''" :enable-attachments="activeDialog && activeDialog.has_responses" @send="onMsgSend" @update="onMsgUpdated"
        ref="chatForm" @input="onMsgInput" />
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { pushModal } from "jenesius-vue-modal"

import { apiCall, apiEventTypes, SERVER_ADDRESSES } from "@/utils/api";

import PaymentModal from '@/components/modals/payment/PaymentModal'
import PageTitle from '@/components/PageTitle'
import UsersList from '@/components/UsersList'
import MessageReadFlag from '@/components/messenger/MessageReadFlag'
import MessageLastText from '@/components/messenger/MessageLastText'

import ChatHeader from '@/components/messenger/ChatHeader.vue'
import MessagesList from '@/components/messenger/MessagesList.vue'
import ChatForm from '@/components/messenger/ChatForm.vue'

// mobile
import mixinDevice from "@/mixins/device.js"
import MobileHeader from '@/components/mobile/MobileHeader'

export default {
  components: {
    PageTitle, UsersList, MessageReadFlag, MessageLastText,
    // chat
    ChatHeader, MessagesList, ChatForm,
    // mobile
    MobileHeader
  },
  mixins: [mixinDevice],
  props: {
    "relationType": {
      type: String,
      required: true
    },
    "uid": {
      type: String,
      default: null,
      required: false
    }
  },
  data() {
    return {
      relations: [],
      checkedUids: [],
      pageSize: 50,
      searchFormHeight: '0rem',
      chatHeaderHeight: 0,
      chatFormHeight: 0,
      searchVal: "",
      isPageLoading: false,
      isLoadedToEnd: false,
      drafts: {},  // key - to_id, value - msg 
      reloadUsersHandlerId: null,
      relationTypeOptions: [
        {
          value: "likes",
        },
        {
          value: "guests",
        },
        {
          value: "pairs",
        },
        {
          value: "favorites",
        },
        {
          value: "messages",
        }
      ],
      types: {
        "guests": {
          title: "Гости",
          respField: "visits",
          icon: 'icon-guests-o',
          mapper: this.likesMapper
        },
        "likes": {
          title: "Кому я нравлюсь",
          respField: "swipes",
          icon: 'icon-liked-o',
          mapper: this.likesMapper
        },
        "pairs": {
          title: "Мои пары",
          respField: "swipes",
          icon: 'icon-pairs-o',
          mapper: this.likesMapper
        },
        "favorites": {
          title: "Избранные",
          respField: "favorites",
          icon: 'icon-menu-favorites',
          selectable: true,
          mapper: this.favoritesMapper
        },
        "messages": {
          title: "Сообщения",
          respField: "dialogs",
          icon: 'icon-msg',
          mapper: this.messagesMapper
        }
        // TODO: кто мне нравится icon-liked-mine-o
      }
    }
  },
  computed: {
    ...mapGetters(["myId", "me", "myEvents", "usersStore", "bottomBarVisible"]),
    apiUrl() {
      let url = ""
      if (this.relationType == "messages") {
        url = SERVER_ADDRESSES.dialogs + this.myId
      } else {
        url = SERVER_ADDRESSES.users + this.relationType
      }
      url += "?limit=" + this.pageSize

      let fromDate = this.relations.length > 0 ? this.relations[this.relations.length - 1].date : ""
      if (fromDate) {
        url += '&from_date=' + encodeURIComponent(fromDate)
      }
      return url
    },
    uids() {
      return this.relations.map(d => d.id)
    },
    gender() {
      return this.me ? this.me.profile.gender : 'male'
    },
    title() {
      return this.types[this.relationType].title
    },
    isSelectable() {
      return this.types[this.relationType].selectable ?? false
    },
    filteredRelations() {
      let search = this.searchVal.toLowerCase()
      let filteredList = this.relations.filter(r => r.id[0] != '-' && r.id in this.usersStore && this.usersStore[r.id].profile.name.toLowerCase().indexOf(search) !== -1)
      return filteredList
    },
    isChatVisible() {
      return this.relationType == 'messages' || (this.isMobileDevice && window.outerWidth >= 768)
    },
    usersDisplayType() {
      if (this.isChatVisible || window.outerWidth < 768) {
        return 'list'
      }
      return 'grid'
    },
    dialogsBlockClass() {
      return {
        "pure-u-md-10-24 pure-u-xl-8-24": this.isChatVisible,
        "hidden-xs": this.uid,
        "hidden-sm": this.uid
      }
    },
    chatBlockClass() {
      return {
        "pure-u-md-14-24 pure-u-xl-16-24": true,
        "hidden-xs": !this.uid,
        "hidden-sm": !this.uid,
      }
    },
    activeDialog() {
      return this.relations.find(r => r.id == this.uid)
    },
    messagesListHeight() {
      const bordersHeight = 3
      let options = ["100vh", (this.chatFormHeight + this.chatHeaderHeight + bordersHeight) + 'px']
      if (this.bottomBarVisible) {
        options.push('var(--bottom-bar-height)')
      }
      return 'calc(' + options.join(' - ') + ')'
    }
  },
  watch: {
    relationType: {
      handler() {
        this.$store.dispatch("SHOW_BOTTOM_BAR")
        this.reload()
      },
      immediate: true
    },
    myEvents: {
      handler(newVal) {
        if (!newVal || !newVal.guests) {
          return
        }
        this.$store.dispatch('DECREMENT_MY_EVENT', {
          type: 'guests',
          value: newVal.guests
        });
      },
      immediate: true
    },
    uid: {
      handler() {
        this.$store.dispatch("TOGGLE_BOTTOM_BAR", !this.uid || (this.isChatVisible && window.outerWidth >= 768))
        this.$nextTick(() => {
          this.chatHeaderHeight = this.$refs.chatHeader.$el.offsetHeight
          this.chatFormHeight = this.$refs.chatForm.$el.offsetHeight
        })
        // this.emitSelectedDialogUpdated()
        // TODO: т.к. диалоги могут быть не загружены - то и в списке диалогов не будет нужного
        // и мы не уменьшим количество событий в asideBlock - баг
        const dlgIndex = this.getDialogIndex(this.uid),
          dialog = dlgIndex === -1 ? null : this.relations[dlgIndex]
        if (!dialog || !dialog.unread_count) {
          return
        }
        this.$store.dispatch('DECREMENT_MY_EVENT', {
          type: 'messages',
          value: dialog.unread_count
        });
        dialog.unread_count = 0;
      },
      immediate: true
    }
  },
  mounted() {
    this.searchFormHeight = this.$refs.searchForm ? this.$refs.searchForm.offsetHeight + 'px' : '0px'
    this.$options.sockets.onmessage = this.wsEvent
    this.reloadUsersHandlerId = setInterval(() => {
      this.$store.dispatch("LOAD_USERS", {uids: this.uids})
    }, 45000);
  },
  unmounted: function () {
    this.$options.sockets.onmessage = undefined
    clearInterval(this.reloadUsersHandlerId)
  },
  methods: {
    onRelationTypeChange(val) {
      this.$router.push({ name: "Relations", params: { relationType: val } })
    },
    reload() {
      this.deselectAll()
      this.isPageLoading = false
      this.isLoadedToEnd = false
      this.relations = []
      this.loadPage()
    },
    loadPage() {
      if (this.isPageLoading || this.isLoadedToEnd) {
        return
      }
      this.isPageLoading = true
      this.loadRelations().then(relations => {
        if (relations.length < this.pageSize) {
          this.isLoadedToEnd = true
        }

        this.relations = [
          ...this.relations,
          ...relations
        ]
        if (this.relationType == "messages" && this.uid && this.uids.indexOf(this.uid) === -1) {
          this.relations.unshift(this.createEmptyDialog(this.uid))
        }
        this.$store.dispatch("LOAD_USERS", {uids: this.uids})
        this.isPageLoading = false
      })
    },
    async loadRelations() {
      var resp = await apiCall({ url: this.apiUrl, method: "GET" })
      return resp[this.types[this.relationType].respField].map(this.types[this.relationType].mapper)
    },
    likesMapper: (r) => ({
      id: r.from_id,
      date: r.date
    }),
    favoritesMapper: (r) => ({
      id: r.to_id,
      date: r.date
    }),
    messagesMapper: (r) => ({
      ...r,
      id: r.to_id
    }),

    onProfileClick(e, uid) {
      if (uid[0] == '-') {  // hidden fake id
        e.preventDefault()
        e.stopPropagation()
        this.openPayment()
      }
    },
    selectAll() {
      this.checkedUids = this.relations.map(f => f.id)
    },
    deselectAll() {
      this.checkedUids = []
    },
    async removeSelected() {  // пока только работает с favorites
      this.isPageLoading = true

      for (var uid of this.checkedUids) {
        await this.$store.dispatch("SET_USER_FAVORITE", {
          uid: uid,
          isFavorite: false
        })
      }

      this.reload()
    },
    openPayment() {
      pushModal(PaymentModal)
    },

    // chat
    wsEvent(event) {
      let data = JSON.parse(event.data)
      if (data.from_id) {
        this.$store.dispatch("SET_USER_ONLINE", {
          uid: data.from_id,
          isOnline: true
        })
      }
      if (this.relationType != "messages") {
        return
      }
      if (data.type == apiEventTypes.CHAT_TYPING) {
        this.$store.dispatch("START_TYPING", data.from_id)
      } else if (data.type == apiEventTypes.CHAT_MESSAGE) {
        let msg = data.data.data.message
        this.onNewMessage(msg, data.data.data.new_dialog)
      } else if (data.type == apiEventTypes.CHAT_READ) {
        this.setPartnerRead(data.from_id, data.data.data.date)
      }
    },
    onMsgInput(text) {
      if (!text) {
        delete this.drafts[this.uid]
      } else {
        this.drafts[this.uid] = text
      }
      this.chatFormHeight = this.$refs.chatForm.$el.offsetHeight
    },
    onMsgSend(e) {
      this.$refs.msgList.updateMessage(e.message)
      this.$refs.msgList.scrollToBottom()
    },
    onMsgUpdated(e) {
      if (!e.message) {
        return
      }
      this.$refs.msgList.updateMessage(e.message)
      this.onNewMessage(e.message, e.newDialog)
    },
    createEmptyDialog(toId) {
      return {
        id: toId,
        from_id: this.myId,
        to_id: toId,
        unread_count: 0,
        has_responses: false,
        partner_read_until: null,
      }
    },
    onNewMessage(msg, initiateDialog) {
      let isMine = msg.from_id == this.myId,
        dlgUserId = isMine ? msg.to_id : msg.from_id,
        dlgIndex = this.getDialogIndex(dlgUserId),
        dialog = null
      if (dlgIndex === -1 && initiateDialog) {
        dialog = this.createEmptyDialog(dlgUserId)
        dialog.date = msg.date
      } else if (dlgIndex !== -1) {
        dialog = this.relations.splice(dlgIndex, 1)[0]
      } else {
        return
      }
      dialog.last_msg = msg
      if (!isMine) {
        dialog.has_responses = true
      }
      this.relations.splice(0, 0, dialog);
      this.relations[0].unread_count = this.uid == dlgUserId ? 0 : dialog.unread_count + 1
      this.$store.dispatch("STOP_TYPING", dlgUserId)
    },
    onDelDialog(uid) {
      if (this.relationType != "messages") {
        return
      }
      apiCall({ url: SERVER_ADDRESSES.dialogs + this.myId + '/' + uid + '/remove', method: "POST" })
      this.$router.push({ name: 'Relations', params: { relationType: 'messages' } })
      this.relations = this.relations.filter(r => r.id != uid)
    },
    setPartnerRead(uid, date) {
      let dlgIndex = this.getDialogIndex(uid)
      if (dlgIndex === -1) {
        return
      }
      this.relations[dlgIndex].partner_read_until = date
    },
    async repeatMessage(msg) {
      const tempMessage = this.$refs.chatForm.createTempMessage(msg.text)
      await this.$refs.chatForm.sendMessage(tempMessage)
    },
    getDialogIndex(to_id) {
      if (this.relationType != "messages") {
        return -1
      }
      return this.relations.findIndex(d => d.to_id == to_id)
    }
  }
}
</script>
  


<style scoped>
.relations-list,
.chat-block {
  height: 100%;
}

.chat-block {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ECECEC;
}

.chat-block .messages {
  flex: 1 1 auto;
}

.grey-input {
  border: none;
  outline: none;
  padding-top: .377em;
  padding-bottom: .377em;
}

.grey-input i {
  color: #868585;
}

.loading-block {
  min-height: 5rem;
}

.top-star {
  position: relative;
}

.top-star i {
  position: absolute;
  top: 0;
  font-size: 1.25rem;
  color: #fff200;
}

.checkbox label {
  display: inline-block;
  padding: .6em;
  margin: -0.6em;
}

.checkbox label:before {
  position: relative;
  background-color: rgba(255, 255, 255, .4);
  border-color: #b5b5b5;
  transition: all .2s;
}

.checkbox label:hover:before {
  background-color: rgba(255, 255, 255, .75);
  border-color: #b5b5b5;
}

.btn-more {
  border-radius: 0;
  color: #000;
}

.unread {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #FF426F;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
}

.type-option {
  display: block;
  padding: 0 .5rem;
  line-height: 1.8;
}

.type-option i {
  margin-right: .5rem;
}
</style>