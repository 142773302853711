<template>
  <div class="pure-g container-sm">
    <template v-if="isMobileDevice">
    
      <h3 class="pure-u-1 txt-c">
        Фильтр поиска
      </h3>
      <div class="pure-u-1 pure-u-md-1-2 form-field inner-top-label" v-bind:class="{error: !search.city_id && showErrors}">
        <label for="search__city">Из города</label>
        <CityPicker
          inputId="search__city"
          v-model="search.city_id" />
      </div>

      <div class="pure-u-1 pure-u-md-1-2 form-field inner-top-label" v-bind:class="{error: !search.genders && showErrors}">
        <label for="search__gender">Пол</label>
        <GenderPicker
          inputId="search__gender"
          type="looking_for"
          v-model="search.genders" />
      </div>

      <div class="pure-u-1 form-field inner-top-label">
        <label for="search__age">Возраст</label>
        <div class="pure-g age-block">
          <div class="pure-u-16-24 slider-mobile">
            <Slider v-model="age" :min="minAge" :max="maxAge" :lazy="false" :tooltips="false" />
          </div>
          <div class="pure-u-8-24 block-vcenter txt-c">
            от {{ age[0] }} до {{ age[1] }}
          </div>
        </div>
      </div>

      <div class="pure-u-1 form-field">
        <div class="pure-g">
          <div class="pure-u-1-2 padding-half checkbox" v-for="item in otherList" :key="item.vale">
            <input type="checkbox" v-bind:checked="other.indexOf(item.value) !== -1" :id="'checkbox__' + item.value" @change="onOtherSelected(item.value)" />
            <label :for="'checkbox__' + item.value">{{ item.label }}</label>
          </div>
        </div>
      </div>
      
      <div class="pure-u-1 pure-u-md-16-24"></div>
      <div class="pure-u-1 pure-u-md-8-24 form-field">
        <button class="btn btn-red full-width" @click="updateSearch">Поиск</button>
        <VueElementLoading :active="isSearchUpdating" size="20"/>
      </div>
    </template>

    <template v-else>
      <div class="pure-u-1 pure-u-sm-1-2 pure-u-md-5-24 pure-u-lg-6-24 form-field" v-bind:class="{error: !search.city_id && showErrors}">
        <CityPicker v-model="search.city_id" />
      </div>

      <div class="pure-u-1 pure-u-sm-1-2 pure-u-md-5-24 pure-u-lg-4-24 form-field" v-bind:class="{error: !search.genders && showErrors}">
        <GenderPicker
          type="looking_for"
          v-model="search.genders" />
      </div>

      <div class="pure-u-12-24 pure-u-sm-8-24 pure-u-md-6-24 pure-u-lg-6-24 form-field">
        <VDropdown placement="bottom" :distance="5">
          <button class="btn btn-input-mimic no-padding-h pure-g">
            <span class="pure-u-1-2 txt-c divider-v">Возраст&nbsp;&nbsp;</span>
            <span class="pure-u-1-2 txt-c">{{ age[0] }}-{{ age[1] }}</span>
          </button>

          <template #popper>
            <div class="slider">
              <Slider v-model="age" :min="minAge" :max="maxAge" :options="sliderOptions" tooltipPosition="bottom" />
            </div>
          </template>
        </VDropdown>
      </div>

      <div class="pure-u-12-24 pure-u-sm-8-24 pure-u-md-4-24 pure-u-lg-4-24 form-field">
        <VueSelect
          :options="otherList"
          :clearable="false"
          :searchable="false"
          :close-on-select="false"
          @option:selecting="onOtherSelected"
          placeholder="Другое">

          <template #selected-option-container>
            <div class="vs__selected">Другое</div>
          </template>

          <template #option="{ value, label }">
            <div class="checkbox">
              <input type="checkbox" v-bind:checked="other.indexOf(value) !== -1" />
              <label>{{ label }}</label>
            </div>
          </template>

        </VueSelect>
      </div>

      <div class="pure-u-1 pure-u-sm-8-24 pure-u-md-4-24 form-field">
        <button class="btn btn-red full-width" @click="updateSearch">Поиск</button>
        <VueElementLoading :active="isSearchUpdating" size="20"/>
      </div>
    </template>
  </div>
</template>

<script>
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'

import CityPicker from '@/components/inputs/CityPicker.vue'
import GenderPicker from '@/components/inputs/GenderPicker.vue'

import mixinDevice from "@/mixins/device.js"
import {apiCall, SERVER_ADDRESSES} from "@/utils/api";
import { mapGetters } from "vuex";


export default {
  name: 'FormSearch',
  mixins: [mixinDevice],
  components: {
    Slider,
    CityPicker,
    GenderPicker
  },
  data: function() {
    return {
      showErrors: false,

      sliderOptions: {
        margin: 4
      },
      other: [],
      otherList: [
        {value: "online", label: "Онлайн"},
        {value: "photo", label: "С фото"},
      ],

      isSearchUpdating: false,

      minAge: 18,
      maxAge: 70,
      age: [18, 70],

      search: {
        genders: null,
        city_id: null
      }
    }
  },
  computed: {
    ...mapGetters(["me"])
  },
  watch: {
    me: {
      handler() {
        if (!this.me || !this.me.search) {
          this.$store.dispatch("REQUEST_MY_PROFILE")
          return
        }
        let data = {...this.me.search}
        data.genders = data.genders.join(",")
        this.age = [this.me.search.age_from, this.me.search.age_to]
        this.search = data
      },
      immediate: true
    }
  },
  methods: {
    updateSearch() {
      this.showErrors = !this.search.genders || !this.search.city_id
      if (this.showErrors) {
        return
      }
      let data = {...this.search}
      this.isSearchUpdating = true
      apiCall({ url: SERVER_ADDRESSES.users + this.me.id + "/update/search", method: "POST", data: {
            ...data,
            age_from: this.age[0],
            age_to: this.age[1]
          }
        }).then(() => {
          this.isSearchUpdating = false
          this.$store.dispatch("REQUEST_MY_PROFILE")
          this.$emit('search', {...data, other: this.other})
        })
    },
    onOtherSelected(e) {
      if (!e.value) {
        e = {value: e}
      }
      let index = this.other.indexOf(e.value)
      if (index !== -1) {
        this.other.splice(index, 1);
      } else {
        this.other.push(e.value)
      }
    },
  }
}
</script>

<style scoped>
h3 {
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
}

.form-field {
  margin-bottom: 1rem;
}

.divider-v {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
  padding: 0 .5rem;
  line-height: 2.33rem;
  border-right: 1px solid #DEDEDE;
}

.checkbox label {
  color: #000
}
.mimic-select-header {
  font-weight: 600;
  color: #222020;
}

.slider {
  padding: 1.5rem 1.5rem 3rem 1.5rem;
  min-width: 15rem;
}


.age-block {
  border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
  border-radius: var(--vs-border-radius);
}
.slider-mobile {
  padding: 1.59rem 1.5rem 1.25rem 1.5rem;
}
</style>
