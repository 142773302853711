<template>
  <div>
    <div v-if="isMobileDevice">
      <MobileHeader disableBack/>
    </div>
    <form method="GET" @submit.prevent="" class="padding" ref="searchForm">
      <div class="prefixed block-vcenter grey-input padding-half-l">
        <i class="icon-search margin-half"></i>
        <input class="grey-input no-padding-l" type="text" placeholder="Поиск..." v-model="searchVal" />
      </div>
    </form>
    <hr class="no-margin" />
    <div class="dialogs" :class="{ 'empty-block-wrapper': isEmpty }" ref="dialogs">
      <CustomScrollbar :style="{ 'height': 'calc(100vh - 3.6rem - ' + searchFormHeight + ')' }">
        <div class="hidden-md hidden-lg hidden-xl empty" v-if="isEmpty">
          <div class="txt-c">
            <p>
              <img src="@/assets/img/empty/clouds-and-hearts.svg" />
            </p>
            <p>
              Здесь пока пусто. Начните общаться прямо сейчас!
              Ищите людей рядом с вами и заводите новые знакомства!
            </p>
            <router-link :to="{ name: 'Near' }" class="btn btn-red">
              Поиск
            </router-link>
          </div>
        </div>

        <DialogEntry v-else v-for="d in filteredDialogs" :key="d.to_id" :dialog="d" :typing="d.to_id in typings" />
        <div class="pure-u-1 loading-block" v-if="isPageLoading">
          <VueElementLoading :active="true" size="40" />
        </div>
      </CustomScrollbar>
    </div>
  </div>
</template>

<script>
import '@/assets/css/empty-list.css'

import { mapGetters } from "vuex";

import { apiCall, apiEventTypes, SERVER_ADDRESSES } from "@/utils/api";

import DialogEntry from '@/components/messenger/DialogEntry.vue'

import mixinDevice from "@/mixins/device.js"
// mobile
import MobileHeader from '@/components/mobile/MobileHeader'

export default {
  name: 'DialogsList',
  mixins: [mixinDevice],
  props: {
    "uid": {
      type: String,
      default: null
    }
  },
  components: {
    DialogEntry,
    // mobile
    MobileHeader
  },
  emits: ['dialogUpdated', 'listUpdated'],
  data: function () {
    return {
      isPageLoading: false,
      searchVal: "",
      searchFormHeight: '0rem',
      reloadUsersHandlerId: null,
      dialogs: [],
      typings: {}
    }
  },
  computed: {
    ...mapGetters(["myId", "usersStore"]),
    isEmpty() {
      return !this.isPageLoading && !this.uid && this.dialogs.length === 0
    },
    uids() {
      return this.dialogs.map(d => d.to_id)
    },
    filteredDialogs() {
      let search = this.searchVal.toLowerCase()
      let filteredList = this.dialogs.filter(d => d.to_id in this.usersStore && this.usersStore[d.to_id].profile.name.toLowerCase().indexOf(search) !== -1)
      return filteredList
    }
  },
  watch: {
    uid: {
      handler() {
        this.emitSelectedDialogUpdated()
        // TODO: т.к. диалоги могут быть не загружены - то и в списке диалогов не будет нужного
        // и мы не уменьшим количество событий в asideBlock - баг
        const dlgIndex = this.getDialogIndex(this.uid),
          dialog = dlgIndex === -1 ? null : this.dialogs[dlgIndex]
        if (!dialog || !dialog.unread_count) {
          return
        }
        this.$store.dispatch('DECREMENT_MY_EVENT', {
          type: 'messages',
          value: dialog.unread_count
        });
        dialog.unread_count = 0;
      },
      immediate: true
    }
  },
  mounted() {
    this.loadPage()
    this.searchFormHeight = this.$refs.searchForm.offsetHeight + 'px'
    this.$options.sockets.onmessage = this.wsEvent
    this.reloadUsersHandlerId = setInterval(() => {
      this.$store.dispatch("LOAD_USERS", {uids: this.uids})
    }, 45000);
  },
  unmounted: function () {
    this.$options.sockets.onmessage = undefined
    clearInterval(this.reloadUsersHandlerId)
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadDialogs().then(dialogs => {
        this.dialogs.push(...dialogs)

        if (this.uid && this.uids.indexOf(this.uid) === -1) {
          this.dialogs.unshift(this.createEmptyDialog(this.uid))
        }

        this.$store.dispatch("LOAD_USERS", {uids: this.uids, force: true})
        this.isPageLoading = false
        this.emitSelectedDialogUpdated()
        this.$emit("listUpdated", {
          count: this.dialogs.length
        })
      })
    },
    async loadDialogs() {
      let url = SERVER_ADDRESSES.dialogs + this.myId,
        fromDate = this.dialogs.length > 0 ? this.dialogs[this.dialogs.length - 1].date : ""
      if (fromDate) {
        url += '?from_date=' + encodeURIComponent(fromDate)
      }
      var resp = await apiCall({ url: url, method: "GET" })
      return resp.dialogs
    },
    wsEvent(event) {
      let data = JSON.parse(event.data)
      if (data.from_id) {
        this.$store.dispatch("SET_USER_ONLINE", {
          uid: data.from_id,
          isOnline: true
        })
      }
      if (data.type == apiEventTypes.CHAT_TYPING) {
        this.startTyping(data.from_id)
      } else if (data.type == apiEventTypes.CHAT_MESSAGE) {
        let msg = data.data.data.message
        this.onNewMessage(msg, data.data.data.new_dialog)
      } else if (data.type == apiEventTypes.CHAT_READ) {
        this.setPartnerRead(data.from_id, data.data.data.date)
      }
    },
    startTyping(to_id) {
      if (to_id in this.typings) {
        clearTimeout(this.typings[to_id])
      }
      this.typings[to_id] = setTimeout(() => {
        this.stopTyping(to_id)
      }, 6000)
    },
    stopTyping(to_id) {
      if (to_id in this.typings) {
        clearTimeout(this.typings[to_id])
      }
      delete this.typings[to_id]
    },
    createEmptyDialog(toId) {
      return {
        from_id: this.myId,
        to_id: toId,
        unread_count: 0,
        has_responses: false,
        partner_read_until: null,
      }
    },
    onNewMessage(msg, initiateDialog) {
      let isMine = msg.from_id == this.myId,
        dlgUserId = isMine ? msg.to_id : msg.from_id,
        dlgIndex = this.getDialogIndex(dlgUserId),
        dialog = null
      if (dlgIndex === -1 && initiateDialog) {
        dialog = this.createEmptyDialog(dlgUserId)
        dialog.date = msg.date
      } else if (dlgIndex !== -1) {
        dialog = this.dialogs.splice(dlgIndex, 1)[0]
      } else {
        return
      }
      dialog.last_msg = msg
      if (!isMine) {
        dialog.has_responses = true
      }
      this.dialogs.splice(0, 0, dialog);
      this.dialogs[0].unread_count = this.uid == dlgUserId ? 0 : dialog.unread_count + 1
      this.$nextTick(() => {
        this.stopTyping(dlgUserId)
        if (this.dialogs[0].to_id == this.uid) {
          //this.emitSelectedDialogUpdated()
          this.$emit("dialogUpdated", this.dialogs[dlgIndex])
        }
      })
    },
    onDelDialog(uid) {
      let dlgIndex = this.getDialogIndex(uid)
      this.dialogs.splice(dlgIndex, 1)
      apiCall({ url: SERVER_ADDRESSES.dialogs + this.myId + '/' + uid + '/remove', method: "POST" })
      this.$router.push({ name: 'Relations', params: { relationType: 'messages' } })
    },
    setPartnerRead(uid, date) {
      let dlgIndex = this.getDialogIndex(uid)
      if (dlgIndex === -1) {
        return
      }
      this.dialogs[dlgIndex].partner_read_until = date
    },
    emitSelectedDialogUpdated() {
      this.$store.dispatch("SET_ACTIVE_DIALOG", this.uid)
      if (!this.uid) {
        return
      }
      let dlgIndex = this.getDialogIndex(this.uid)
      if (dlgIndex === -1) {
        return
      }
      this.$emit("dialogUpdated", this.dialogs[dlgIndex])
    },
    getDialogIndex(to_id) {
      return this.dialogs.findIndex(d => d.to_id == to_id)
    }
  }
}
</script>


<style scoped>
.grey-input {
  border: none;
  outline: none;
  padding-top: .377em;
  padding-bottom: .377em;
}

.grey-input i {
  color: #868585;
}

.loading-block {
  min-height: 5rem;
}

/* md+ */
@media screen and (min-width: 48em) {
  .empty-block-wrapper {
    background-color: transparent;
  }
}
</style>