<template>
  <Swiper
    class="slider nav-dark"
    ref="swiper"
    :modules="swiperModules"
    :navigation="navigation"
    :pagination="pagination"
    :initialSlide="0"
    :slides-per-view="1"
    :space-between="100"
    :loop="true">

    <SwiperSlide class="slide" v-for="(adv, index) in advantages" :key="'advantage_' + index">
      <div class="pure-g container block-vcenter">
        <img class="pure-u-1-3" :src="adv.icon" />
        <p class="pure-u-2-3" v-html="shorten ? adv.htmlShorten : adv.html"></p>
      </div>

    </SwiperSlide>

  </Swiper>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

import 'swiper/swiper.min.css';
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";

import iconWoman from '@/assets/img/empty/woman1.svg'
import iconMan from '@/assets/img/empty/man1.svg'
import iconCouple from '@/assets/img/empty/couple1.svg'
import iconHearts from '@/assets/img/empty/hearts1.svg'

export default {
  name: 'AdvantagesSlider',
  components: {
    Swiper, SwiperSlide
  },
  props: {
    navigation: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    shorten: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      swiperModules: [Navigation, Pagination],
      advantages: [
        {
          icon: iconWoman,
          html: 'Общайтесь <b>без ограничений</b> в созданных диалогах. Пишите новые сообщения каждый день новым людям',
          htmlShorten: 'Общайтесь <b>без ограничений</b> в созданных диалогах.'
        },
        {
          icon: iconMan,
          html: 'Ставьте <b>больше лайков!</b> Чем больше поставите, тем больше взаимных получите!',
          htmlShorten: 'Ставьте <b>больше лайков!</b> Чем больше поставите, тем больше взаимных получите!'
        },
        {
          icon: iconCouple,
          html: 'Узнайте, <b>кто смотрел вашу анкету</b> или <b>кто поставил вам лайк</b>. Ставьте взаимные и общайтесь!',
          htmlShorten: 'Узнайте, <b>кто смотрел вашу анкету</b> или <b>кто поставил вам лайк</b>.'
        },
        {
          icon: iconHearts,
          html: 'Скройте себя в гостях других. <b>Никто не узнает</b>, что вы смотрели их профиль',
          htmlShorten: 'Скройте себя в гостях других. <b>Никто не узнает</b>, что вы смотрели их профиль'
        },
      ]
    }
  }
}
</script>

<style>
.swiper .swiper-wrapper {
  align-items: center;
}
</style>

<style scoped>
.slider .slide .container {
  max-width: 30rem;
}

.slider .slide p {
  font-weight: 400;
  font-size: 1.1rem;
  color: #1A3B5A;
}
</style>