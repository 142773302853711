const state = {
  typingUids: {},
  typingUidTimeoutCallbacks: {},
};


const getters = {
  typingStore: state => state.typingUids,
};


const actions = {
  ["START_TYPING"]: ({ state, dispatch }, uid) => {
    return new Promise(() => {
      state.typingUids[uid] = true
      clearTimeout(state.typingUidTimeoutCallbacks[uid])
      state.typingUidTimeoutCallbacks[uid] = setTimeout(() => {
        dispatch("STOP_TYPING", uid)
      }, 6000)
    })
  },
  ["STOP_TYPING"]: ({ state }, uid) => {
    return new Promise(() => {
      delete state.typingUids[uid]
      clearTimeout(state.typingUidTimeoutCallbacks[uid])
      delete state.typingUidTimeoutCallbacks[uid]
    })
  },
};

export default {
  state,
  getters,
  actions,
};