<template>
  <span :class="{ 'active': isActive }" class="last-msg typing" v-if="typing">Печатает...</span>
  <span :class="{ 'active': isActive }" class="last-msg" v-else-if="lastMsg && lastMsg.text">{{ lastMsg.text }}</span>
  <span :class="{ 'active': isActive }" class="last-msg" v-else-if="lastMsg && lastMsg.has_attachments">Фотография</span>
  <span :class="{ 'active': isActive }" class="last-msg" v-else>&nbsp;</span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    "uid": {
      required: true,
      type: String
    },
    "lastMsg": {
      required: false,
      default: null
    },
    "isActive": {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(["myId", "typingStore"]),
    typing() {
      return this.typingStore[this.uid]
    }
  }
}
</script>

<style scoped>
.dialog .last-msg {
  display: block;
  font-size: .9rem;
  max-height: 1.2em;
  overflow: hidden;
  line-height: 1.1em;
  color: #646363;
}

.last-msg.typing {
  color: #359EFF;
}

.last-msg.active,
.last-msg.active.typing {
  color: #FFF;
}
</style>