<template>
  <div class="payment-modal mobile-fullscreen" :class="{mobile: isMobileDevice}">
    <div class="header-modal">
      <div v-if="isMobileDevice" class="padding-half flex-row block-vcenter">
        <button class="btn" type="button" @click="close"><i class="icon-angle-left"></i></button>
        <span class="flex-expand txt-c" style="font-size: 1.2rem">Оплата</span>
      </div>
      <button v-else class="btn btn-modal-close" type="button" @click="close"><i class="icon-times"></i></button>

      <Swiper class="flex-row block-vcenter txt-c" direction="horizontal" slides-per-view="auto">
        <SwiperSlide style="width: 180px">
          <button class="btn btn-type" :class="{ 'active': paymentType == 'card' }" @click="paymentType = 'card'">
            Оплата картой
          </button>
        </SwiperSlide>
        <SwiperSlide style="width: 80px">
          <button class="btn btn-type" :class="{ 'active': paymentType == 'qiwi' }" @click="paymentType = 'qiwi'">
            Qiwi
          </button>
        </SwiperSlide>
        <SwiperSlide style="width: 85px">
          <button class="btn btn-type" :class="{ 'active': paymentType == 'sms' }" @click="paymentType = 'sms'">
            СМС
          </button>
        </SwiperSlide>
        <SwiperSlide style="width: 190px">
          <button class="btn btn-type" :class="{ 'active': paymentType == 'invite' }" @click="paymentType = 'invite'">
            Другие способы
          </button>
        </SwiperSlide>
      </Swiper>
    </div>

    <CustomScrollbar class="modal-content-wrapper" :style="{'max-height': isMobileDevice ? 'calc(100vh - 10rem)' : 'calc(100vh - 2rem)'}">
      <div v-show="paymentType != 'invite'">
        <h4 class="txt-c" v-if="timeout > 0">
          <span class="timer">{{ timer }}</span>
          - На сегодня все
        </h4>

        <p class="description txt-c" v-if="timeout > 0 && type == 'limit_dialogs'">
          Вы сможете создавать новые диалоги через указанное время или подключите Premium, чтобы убрать ограничения
        </p>

        <AdvantagesSlider navigation v-if="type != 'push_top' && !isMobileDevice" />
      </div>

      <PaymentModalCard class="margin-v padding-t" v-show="paymentType == 'card'" :type="type" />

      <div v-show="paymentType == 'qiwi'">
        <h4>Оплата через QIWI</h4>
      </div>
      <div v-show="paymentType == 'sms'">
        <h4>Оплата через SMS</h4>
      </div>

      <PaymentModalInvite class="margin-v padding-t" v-show="paymentType == 'invite'" />

    </CustomScrollbar>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

import 'swiper/swiper.min.css';
import "swiper/modules/navigation/navigation.min.css";

import { popModal } from "jenesius-vue-modal"

import mixinDevice from "@/mixins/device.js"

import AdvantagesSlider from '@/components/AdvantagesSlider.vue'
import PaymentModalCard from '@/components/modals/payment/PaymentModalCard.vue'
import PaymentModalInvite from '@/components/modals/payment/PaymentModalInvite.vue'

export default {
  name: 'PaymentModal',
  mixins: [mixinDevice],
  components: {
    Swiper, SwiperSlide,
    AdvantagesSlider,
    PaymentModalCard,
    PaymentModalInvite
  },
  props: {
    type: {  // manual, limit_swipes, limit_dialogs, push_top
      type: String,
      default: 'manual'
    },
    tabDefault: {
      type: String,
      default: 'card'
    },
    timeout: {
      type: Number,
      default: 0
    }
  },
  computed: {
    timer() {
      return new Date(this.timerSeconds * 1000).toISOString().slice(11, 19);
    }
  },
  watch: {
    tabDefault: {
      immediate: true,
      handler(tab) {
        this.paymentType = tab
      }
    }
  },
  data() {
    return {
      isLoading: false,
      timerSeconds: 0,
      paymentType: 'card'
    }
  },
  mounted() {
    if (this.timeout) {
      this.timerSeconds = this.timeout
      setInterval(this.updateTimeout, 1000)
    }
  },
  methods: {
    updateTimeout() {
      this.timerSeconds -= 1
    },
    close() {
      popModal()
    },
  }
}
</script>


<style>
.swiper .swiper-wrapper {
  align-items: center;
}

@media screen and (min-width: 35.5em) {
  .header-modal .swiper-wrapper {
    justify-content: center;
  }
}

.modal-container .modal-item .modal-content-wrapper {
  padding-top: 0;
}

.modal-container .modal-item.mobile .modal-content-wrapper {
  padding: 0 1rem 1rem 1rem;
}

:root .payment-modal {
  --swiper-navigation-size: 20px;
}
</style>

<style scoped>
.payment-modal {
  padding-top: 0;
  width: 50rem;
}

h4 {
  padding-top: 0;
  font-weight: 600;
  font-size: 1.2rem;
}

.description {
  max-width: 30rem;
  margin: 2rem auto;
}

.header-modal {
  max-width: 100%;
}

.header-modal .btn.btn-type {
  font-weight: 400;
  padding-top: 1.75rem;
  font-size: 1.2rem;
  border-radius: 0;
}

.header-modal .btn.btn-type:active {
  box-shadow: none;
}

.header-modal .btn.btn-type.active {
  color: #359EFF;
}

.header-modal .btn.btn-type.active:after {
  content: "";
  position: absolute;
  display: block;
  left: 20%;
  right: 20%;
  bottom: -1px;
  border-bottom: 2px solid;
}

.mobile .header-modal {
  box-shadow: 0px 2px 10px 0px #0000000D;
  border: none
}
.mobile .header-modal .btn.btn-type {
  font-weight: 500;
  padding: .5rem 1rem;
  font-size: 1rem;
  margin: .5rem 0 .75rem;
  border: 1px solid #ECECEC;
  border-radius: .5rem;
}
.mobile .header-modal .btn.btn-type.active {
  background-color: #359EFF;
  color: #FFF;
}
.mobile .header-modal .btn.btn-type.active:after {
  content: none;
}
</style>