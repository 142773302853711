<template>
  <div>
    <MobileHeader title="Рейтинг" v-if="isMobileDevice" defaultBackUrl="/me" />
    <PageTitle v-else title="Рейтинг"></PageTitle>
    <hr class="no-margin"/>

    <div class="rating-block padding">

      <VueElementLoading :active="!me" size="40" />
      <div class="pure-g container-sm">
        <!-- Left column -->
        <div class="pure-u-4-24 hidden-md hidden-lg hidden-xl"></div>
        <div class="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">

          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-like"></i>
              <span class="done" v-if="me && me.daily_swipes > 20"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Сделай 20 свайпов</strong>
              <p>Делай каждый день по 20 свайпов и получишь +20% к рейтингу</p>
              <span>
                Сегодня свайпов: {{ me ? me.daily_swipes : 0 }}
              </span>
            </div>
          </div>

          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-rating-daily-login"></i>
              <span class="done" v-if="me && me.logins_in_a_row >= 7"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Заходи каждый день</strong>
              <p>Заходи в Kvisto каждый день в течение недели без пропусков и получи +5% к рейтингу</p>
              <span>Заходов подряд: {{ me ? me.logins_in_a_row : 0 }}</span>
            </div>
          </div>
          
          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-menu-favorites"></i>
              <span class="done" v-if="me && me.profile.interests_count >= 5"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Добавь 5 интересов</strong>
              <p>Добавь 5 своих интересов – это даст +5% к твоего рейтингу</p>
              <router-link :to="{name: 'ProfileMine'}" class="btn btn-red btn-sm" v-if="!me || me.profile.interests_count < 5">Добавить</router-link>
              <span v-else>Вы добавили как минимум 5 интересов</span>
            </div>
          </div>

          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-rating-top"></i>
              <span class="done" v-if="isMeInTopUsers"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Подняться в ТОП поиска</strong>
              <p>
                Твоя анкета на 24 часа будет на самом верху поиска и игры.
                Тебя увидят тысячи людей
              </p>
              <button class="btn btn-red btn-sm" @click="pushToTop" v-if="!isMeInTopUsers">Подняться в ТОП</button>
              <span v-else>Ваша анкета находится среди ТОП пользователей</span>
            </div>
          </div>

        </div>
        <div class="pure-u-4-24 hidden-md hidden-lg hidden-xl"></div>

        <!-- Right column -->
        <div class="pure-u-4-24 hidden-md hidden-lg hidden-xl"></div>
        <div class="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-rating-invite"></i>
              <span class="done" v-if="myReferrers >= referrersRequired"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Пригласи {{ referrersRequired }} друзей</strong>
              <p>
                Отправь друзьям ссылку на Kvisto. Как только они зарегистрируются,
                ты получишь Kvisto Premium на 1 месяц. Сможешь каждый день писать новые
                сообщения и больше ставить лайков
              </p>
              <button class="btn btn-red btn-sm" @click="inviteFriends">Пригласить друзей</button>
            </div>
          </div>

          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-photo"></i>
              <span class="done" v-if="me && me.profile.photo_count"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Загрузи 1 фотографию</strong>
              <p>Загрузи 1 свою фотографию, где тебя хорошо видно и получи +20% к рейтингу</p>
              <button class="btn btn-red btn-sm" type="button" @click="uploadPhotos" v-if="!me || me.profile.photo_count < 1">Загрузить</button>
              <span v-else>Вы загрузили главное фото профиля</span>
            </div>
          </div>

          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-photo"></i>
              <span class="done" v-if="me && me.profile.photo_count >= 3"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Загрузи 3 фотографии</strong>
              <p>Загрузи ещё 3 свои фотографии и получи +10% к популярности</p>
              <button class="btn btn-red btn-sm" type="button" @click="uploadPhotos" v-if="!me || me.profile.photo_count < 3">Загрузить</button>
              <span v-else>Вы загрузили несколько фотографий</span>
            </div>
          </div>

          <div class="pure-g card">
            <div class="pure-u-1-5 pure-u-sm-1-3 pure-u-lg-1-5 txt-c card__icon">
              <i class="icon-rating-crown"></i>
              <span class="done" v-if="me && me.premium.is_active"><i class="icon-check"></i></span>
            </div>
            <div class="pure-u-4-5 pure-u-sm-2-3 pure-u-lg-4-5 padding-l card__content">
              <strong>Подключить Kvisto Premium</strong>
              <p>
                Создавай новые диалоги каждый день - пиши кому хочешь первый!
                Твои сообщения дойдут даже тем, у кого много входящих или ограничения на них.
                Больше свайпов в игре – ставь в 3 раза больше лайков!
                Скрой своё посещение – тебя не будут видеть в гостях!
                Узнай, кто поставил тебе лайк!
              </p>
              <button class="btn btn-red btn-sm" v-if="!me || !me.premium.is_active" @click="openPayment">Подключить</button>
              <span v-else>Премиум подключен!<br/>осталось дней: {{me ? me.premium.days_left : 0}}</span>
            </div>
          </div>

        </div>
        <div class="pure-u-4-24 hidden-md hidden-lg hidden-xl"></div>

      </div>
    </div>
  </div>
</template>

<script>
import {pushModal} from "jenesius-vue-modal"
import { mapGetters } from "vuex";

import PageTitle from '@/components/PageTitle.vue'
import UploadPhotos from '@/components/modals/UploadPhotos.vue'
import ConfirmAnything from '@/components/modals/ConfirmAnything.vue'
import PaymentModal from '@/components/modals/payment/PaymentModal.vue'

// mobile
import mixinDevice from "@/mixins/device.js"
import MobileHeader from '@/components/mobile/MobileHeader'

export default {
  name: 'Rating',
  mixins: [mixinDevice],
  components: {
    PageTitle,
    // mobile
    MobileHeader
  },
  computed: {
    ...mapGetters(["me", "myId", "myAvatar", "myReferrers", "referrersRequired", "topUsersStore"]),
    isMeInTopUsers() {
      return this.topUsersStore.length && this.topUsersStore.indexOf(this.myId) !== -1
    }
  },
  mounted() {
    this.$store.dispatch("REQUEST_MY_PROFILE")
    this.$store.dispatch("REQUEST_MY_REFERRERS")
  },
  methods: {
    uploadPhotos() {
      pushModal(UploadPhotos, {
        uid: this.myId,
      })
    },
    async pushToTop() {
      const paymentData = {
        type: 'push_top'
      }
      if (!this.myAvatar) {
        pushModal(UploadPhotos, {
          uid: this.myId,
        })
        return
      }
      if (this.me && this.me.privacy.hide_photos) {
        const modal = await pushModal(ConfirmAnything, {
          title: "Сделать фото видимыми?",
          text: "Чтобы попасть в ТОП пользователей, пожалуйста, сделайте видимыми ваши фото для всех. Сейчас ваши фотографии скрыты.",
          confirmText: "Сделать видимыми",
          denyText: "Отмена"
        })
        modal.on('confirmed', () => {
          this.$store.dispatch("UPDATE_PRIVACY", {data: {hide_photos: false} })
          this.openPayment(paymentData)
        })
        return
      }
      this.openPayment(paymentData)
    },
    inviteFriends() {
      this.openPayment({
        tabDefault: 'invite'
      })
    },
    openPayment(data) {
      pushModal(PaymentModal, data)
    }
  }
}
</script>


<style scoped>
.card {
  position: relative;
  border: 1px solid #ECECEC;
  border-radius: .8rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.card__icon {
  position: relative;
}

.card__icon > i {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.6rem;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 50%;
  color: #FFF;
  background-color: #359EFF;
}

.card__content strong {
  font-weight: 500;
}

.card__content p {
  font-size: .8rem;
  color: #646363;
}

.card .done {
  position: absolute;
  right: 0;
  top: -0.2rem;
  display: inline-block;
  width: 1.33rem;
  height: 1.33rem;
  line-height: 1.6rem;
  font-size: .6rem;
  text-align: center;
  border-radius: 50%;
  color: #FFF;
  background-color: #00d26d;
  border: 2px solid;
  z-index: 1;
}
</style>