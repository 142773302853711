<template>
  <span class="card flex-row block-vcenter" :class="{
    'card-mobile': isMobileDevice,
    'card-pc': !isMobileDevice,
    'active': isActive
  }">
    <span class="avatar-wrp margin-half-r">
      <ProfileCircle class="avatar full-width" :uid="uid" />
    </span>
    <span class="flex-expand">
      <span v-if="user" class="name">{{ user.profile.name }}</span>

      <span class="text">
        <slot name="text">
          <template v-if="user && user.profile.city_id">
            <i class="icon-map-marker"></i>&nbsp;<span>{{ city ? city.name : '' }}</span>
          </template>
        </slot>
      </span>
    </span>
    <span class="txt-r">
      <span class="text">
        <slot name="top-right"></slot>
      </span>
      <slot name="bottom-left"></slot>
      <span class="date">{{ dateAsString }}</span>
      <br />
      <slot name="bottom-right"></slot>
    </span>
  </span>
</template>

<script>
import moment from 'moment'

import { mapGetters } from "vuex";

import mixinDevice from "@/mixins/device.js"
import ProfileCircle from '@/components/ProfileCircle'

export default {
  name: 'ProfileCardHorizontal',
  mixins: [mixinDevice],
  components: {
    ProfileCircle
  },
  props: {
    "uid": {
      required: true
    },
    "date": {
      required: false
    },
    "isActive": {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(["usersStore", "avatarsStore", "cities"]),
    user() {
      if (!(this.uid in this.usersStore)) {
        this.$store.dispatch("LOAD_USERS", {uids: [this.uid]})
      }
      return this.uid in this.usersStore ? this.usersStore[this.uid] : null
    },
    avatar() {
      if (!(this.uid in this.avatarsStore)) {
        this.$store.dispatch("LOAD_AVATARS", [this.uid])
      }
      return this.uid in this.avatarsStore ? this.avatarsStore[this.uid] : null
    },
    city() {
      if (!this.user) {
        return null
      }
      if (!(this.user.profile.city_id in this.cities)) {
        this.$store.dispatch("LOAD_CITIES", [this.user.profile.city_id])
      }
      return this.user.profile.city_id in this.cities ? this.cities[this.user.profile.city_id] : null
    },
    dateAsString() {
      return this.date ? moment.utc(this.date).local().format('LT') : ''
    },
  },
  created() {
    moment.locale('ru')
  }
}
</script>


<style scoped>
.card {
  position: relative;
  padding: .5rem 1rem;
  text-decoration: none;
  color: #222020;
  transition: all .2s ease-in-out;
  outline: none;
}

.card:hover,
.card:active,
.card:focus {
  background-color: #FAFAFA;
}

.card.active,
.card.active:focus,
.card.active:active,
.card.active:hover {
  background-color: #359EFF;
  color: #FFF;
}

.card.active .text,
.card.active .date,
.card.active .status {
  color: #FFF;
}

.card .avatar {
  box-shadow: inset -1rem -19rem 5rem -15rem rgba(0, 0, 0, 0.5), inset -1rem 15rem 5rem -15rem rgba(0, 0, 0, 0.5);

  transition: all .2s;
}

.card:hover .avatar {
  box-shadow: inset -1rem -19rem 5rem -15rem rgba(0, 0, 0, 0.7), inset -1rem 15rem 5rem -15rem rgba(0, 0, 0, 0.7);
}

.avatar-wrp {
  min-width: 3.2rem;
  width: 3.2rem;
  margin: .25rem 1rem .25rem 0;
  display: flex;
}

.avatar-wrp .avatar {
  align-self: end;
}

.name {
  display: block;
  margin: 0 0 .25rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.text {
  display: block;
  font-size: .9rem;
  max-height: 1.2em;
  overflow: hidden;
  line-height: 1.1em;
}

.text,
.date {
  color: #646363;
}
</style>