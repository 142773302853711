<template>
  <div>    
    <template v-if="isMobileDevice">
      <MobileHeader disableBack>
        <template #controls-right>
          <button class="btn btn-transparent btn-filters" @click="openFilters">
            <i class="icon-filters"></i>
          </button>
        </template>
      </MobileHeader>
      <vue-bottom-sheet ref="searchBottomSheet">
        <FormSearch @search="onSearchChange" class="padding-h padding-t" />
      </vue-bottom-sheet>
    </template>

    <template v-else>
      <ScrollTop />
      <FormSearch @search="onSearchChange" class="padding-h padding-t" />
      <hr/>
    </template>

    <div class="pure-g padding container-sm">
      <div class="pure-u-1 empty-block" v-if="uids.length == 0 && !isPageLoading">
        <span class="block txt-c">
          К сожалению, по вашим параметрам мы не смогли найти ни одного пользователя.
          <br/><br/>
          Попробуйте изменить параметры и повторить поиск.
        </span>
      </div>
      <div class="users" :class="{
        'pure-u-1-3 pure-u-md-1-4 pure-u-lg-1-5': isMobileDevice,
        'pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4': !isMobileDevice
      }" v-for="uid in uids" :key="uid">
        <router-link :to="{name: 'Profile', params: { uid: uid }}">
          <ProfileCard class="margin-b" :uid="uid" />
        </router-link>
      </div>
      <div class="pure-u-1 more-cities-warn" v-if="uids_near.length > 0">
        <hr/>
        <span>Еще анкеты поблизости</span>
      </div>
      <div class="users" :class="{
        'pure-u-1-3': isMobileDevice,
        'pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4': !isMobileDevice
      }" v-for="uid in uids_near" :key="uid">
        <router-link :to="{name: 'Profile', params: { uid: uid }}">
          <ProfileCard class="margin-b" :uid="uid" />
        </router-link>
      </div>

      <div class="pure-u-1 loading-block" v-if="isPageLoading">
        <VueElementLoading :active="true" size="40"/>
      </div>
    </div>
  </div>
</template>

<script>
import mixinDevice from "@/mixins/device.js"
import {apiCall, SERVER_ADDRESSES} from "@/utils/api";

import FormSearch from '@/components/FormSearch'
import ProfileCard from '@/components/ProfileCard'
import ScrollTop from '@/components/ScrollTop'

// mobile
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet"
import  "@webzlodimir/vue-bottom-sheet/dist/style.css";

import MobileHeader from '@/components/mobile/MobileHeader'

export default {
  name: 'Near',
  mixins: [mixinDevice],
  components: {
    FormSearch, ProfileCard, ScrollTop,
    // mobile
    VueBottomSheet, MobileHeader
  },
  data: function() {
    return {
      uids: [],
      uids_near: [],
      uids_near_radius_step: 0,
      pagination: 0,
      pageSize: 50,
      isPageLoading: false,
      isLoadedToEnd: false,
      onlyOnline: false,
      onlyWithPhoto: false,
      scrollElement: null,
      lastScrollTopValue: 0
    }
  },
  mounted: function() {
    this.loadPage()
  },
  activated: function() {
    this.scrollElement = document.querySelector('#app > .kvisto-app')
    this.scrollElement.addEventListener("scroll", this.handleScroll)
    this.scrollElement.scrollTop = this.lastScrollTopValue
  },
  deactivated: function() {
    this.scrollElement.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadPeople().then(users => {
        let uids = users.map(u => u.id)
        this.$store.dispatch("LOAD_USERS", {uids})
        if (!this.uids_near_radius_step) {
          this.uids = [...this.uids, ...uids]
        } else {
          this.uids_near = [...this.uids_near, ...uids]
        }
        this.isPageLoading = false

        if (!this.pagination && this.uids_near_radius_step > 5) {
          this.isLoadedToEnd = true
        } else if (!this.pagination) {
          this.uids_near_radius_step++
          this.loadPage()
        }
      })
    },
    async loadPeople() {
      let url = SERVER_ADDRESSES.users + "near?limit=" + this.pageSize + "&online=" + this.onlyOnline + "&photo=" + this.onlyWithPhoto
      if (this.pagination) {
        url += '&pagination=' + this.pagination
      }
      if (this.uids_near_radius_step) {
        url += '&radius_step=' + this.uids_near_radius_step
      }
      var resp = await apiCall({ url: url, method: "GET" })
      this.pagination = resp.pagination
      return resp.users
    },
    resetResults() {
      this.uids = []
      this.uids_near = []
      this.uids_near_radius_step = 0
      this.isLoadedToEnd = false
      this.pagination = null
    },
    openFilters() {
      if (this.$refs.searchBottomSheet) {
        this.$refs.searchBottomSheet.open()
      }
    },
    closeFilters() {
      if (this.$refs.searchBottomSheet) {
        this.$refs.searchBottomSheet.close()
      }
    },
    onSearchChange(e) {
      this.closeFilters()
      this.resetResults()
      this.onlyOnline = !!e.other.find(i => i == 'online')
      this.onlyWithPhoto = !!e.other.find(i => i == 'photo')
      this.loadPage()
    },
    handleScroll() {
      if (this.isLoadedToEnd) {
        return
      }
      let body = this.scrollElement.children[0],
        p = body.parentNode,
        bottomOffset = (p.scrollHeight - p.clientHeight) - (body.scrollTop || p.scrollTop)
      this.lastScrollTopValue = this.scrollElement.scrollTop
      if (!this.isPageLoading && bottomOffset < 1000) {
        this.loadPage()
      }
    }
  }
}
</script>


<style scoped>
.loading-block {
  min-height: 5rem;
}
.empty-block {
  display: flex;
  min-height: 20rem;
  justify-content: center;
  align-items: center;
  color: #908f8f;
}

.more-cities-warn {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.more-cities-warn hr {
  position: relative;
  top: 50%;
  width: 100%;
}
.more-cities-warn span {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  background-color: #FFF;
  padding: 0 2rem;
  text-align: center;
  color: #777;
}

.more-cities-warn span {
  max-width: 40%;
}

.users a {
  text-decoration: none;
}

.btn-filters {
  border-radius: 0;
  color: #000;
}

.btn-filters i {
  position: relative;
  top: .2rem;
}
</style>