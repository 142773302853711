<template>
  <span class="card" :class="{
    'card-mobile': isMobileDevice,
    'card-pc': !isMobileDevice
  }">
    <Avatar
      :thumb="avatar ? avatar.thumbs.face_md : null"
      :hidden="avatar ? avatar.is_hidden : false"
      :gender="user ? user.profile.gender : 'male'"
      :loading="!avatar && avatar !== false"
      :height="isMobileDevice ? 100 : 120"
      :radius="isMobileDevice ? '50%' : '.8rem'"
      class="avatar" />

    <span class="top">
      <span class="flex-row">
        <span class="date" v-if="date">
          {{ dateAsStr }}
        </span>
        <span class="photo-count" v-else-if="user && user.profile.photo_count && !isMobileDevice">
          <i class="icon-photo"></i>
          {{ user.profile.photo_count }}
        </span>
        <span class="flex-expand txt-r">
          <slot name="top-right"></slot>
        </span>
      </span>
    </span>
    <span class="bottom" v-if="user">
      <span class="name" :class="{ online: user.is_online }" v-if="user.profile.name">
        {{ user.profile.name }},&nbsp;{{ user.profile.age }}
      </span>
      <span class="city"  v-if="user.profile.city_id">
        <i class="icon-map-marker"></i>&nbsp;<span>{{ city ? city.name : '' }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import moment from 'moment'

import { mapGetters } from "vuex";

import mixinDevice from "@/mixins/device.js"
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'ProfileCard',
  mixins: [mixinDevice],
  components: {
    Avatar
  },
  props: {
    "uid": {
      required: true
    },
    "date": {
      required: false
    }
  },
  computed: {
    ...mapGetters(["usersStore", "avatarsStore", "cities"]),
    user() {
      if (!(this.uid in this.usersStore)) {
        this.$store.dispatch("LOAD_USERS", {uids: [this.uid]})
      }
      return this.uid in this.usersStore ? this.usersStore[this.uid] : null
    },
    avatar() {
      if (!(this.uid in this.avatarsStore)) {
        this.$store.dispatch("LOAD_AVATARS", [this.uid])
      }
      return this.uid in this.avatarsStore ? this.avatarsStore[this.uid] : null
    },
    city() {
      if (!this.user) {
        return null
      }
      if (!(this.user.profile.city_id in this.cities)) {
        this.$store.dispatch("LOAD_CITIES", [this.user.profile.city_id])
      }
      return this.user.profile.city_id in this.cities ? this.cities[this.user.profile.city_id] : null
    },
    dateAsStr() {
      return moment.utc(this.date).local().calendar()
    }
  },
  created() {
    moment.locale('ru')
  }
}
</script>


<style scoped>
.card {
  display: block;
  position: relative;
  min-height: 5rem;
  overflow: hidden;
}

.card.card-pc {
  border-radius: .8rem;
  background: linear-gradient(181.73deg, rgba(0, 0, 0, 0) 1.47%, rgba(0, 0, 0, 0.1) 51.31%, rgba(0, 0, 0, 0.5) 98.53%);
}

.card.card-mobile {
  text-align: center;
  max-width: 10rem;
  margin: 0 auto;
}

.card .avatar {
  box-shadow: inset -1rem -19rem 5rem -15rem rgba(0, 0, 0, 0.5), inset -1rem 15rem 5rem -15rem rgba(0, 0, 0, 0.5);

  transition: all .2s;
}

.card:hover .avatar {
  box-shadow: inset -1rem -19rem 5rem -15rem rgba(0, 0, 0, 0.7), inset -1rem 15rem 5rem -15rem rgba(0, 0, 0, 0.7);
}

.card.card-pc .top,
.card.card-pc .bottom {
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  width: 100%;
  color: #FFF;
}

.card.card-pc .top {
  top: 0;
  font-size: .8rem;
  padding: .5rem;
}

.card.card-pc .bottom {
  padding: 1rem;
  bottom: 0;
}

.card.card-mobile .top,
.card.card-mobile .bottom {
  text-align: center;
}

.name {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
}

.card.card-mobile .name {
  margin: .5rem 0 .25rem;
  font-size: .9rem;
  font-weight: 500;
  color: #000;
}

.name.online:after {
  content: "";
  display: inline-block;
  width: .5rem;
  height: .5rem;
  margin: 0 0 .15rem .5rem;
  border-radius: 50%;
  background-color: #00FF85;
}

.card.card-mobile .name.online:after {
  content: "";
  position: absolute;
  top: 7%;
  right: 11%;
  margin: 0;
  border: 3px solid #FFF;
}
@media screen and (max-width: 440px) {
  .card.card-mobile .name.online:after {
    top: 5%;
    right: 9%;
  }
}
@media screen and (max-width: 340px) {
  .card.card-mobile .name.online:after {
    right: 5%;
  }
}

.city {
  font-size: .8rem;
  font-weight: 500;
}

.city i {
  opacity: .5;
}

.photo-count {
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
  font-size: .8rem;
  padding: .25rem .5rem;
  border-radius: .25rem;
}

.photo-count i {
  font-size: .8em;
  margin-right: .33rem;
}

.date {
  display: inline-block;
  padding: .25rem .5rem;
}
</style>