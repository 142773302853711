<template>
  <div class="me-wrp">
    <AvatarWithRating />
    <div>
      <router-link class="btn btn-grey-stroke" :to="{ name: 'Settings' }">Настройки</router-link>
      <router-link class="btn btn-grey-stroke" :to="{ name: 'ProfileMine' }">Редактировать</router-link>
    </div>

    <div>
      <div class="slider-wrp margin-v">
        <AdvantagesSlider pagination shorten />
      </div>
      <a class="btn btn-red-stroke" @click="openPayment">Подключить Premium</a>
    </div>
  </div>
</template>

<script>
import {pushModal} from "jenesius-vue-modal"
import { mapGetters } from "vuex";

import AdvantagesSlider from '@/components/AdvantagesSlider.vue'
import AvatarWithRating from '@/components/AvatarWithRating.vue'
import PaymentModal from '@/components/modals/payment/PaymentModal.vue'

export default {
  name: 'AsideMenu',
  components: {
    AdvantagesSlider,
    AvatarWithRating
  },
  computed: {
    ...mapGetters(["me", "myPhotos"]),
  },
  mounted() {
    if (!this.me || !this.me.search) {
      this.$store.dispatch("REQUEST_MY_PROFILE")
    }
    if (this.myPhotos === null) {
      this.$store.dispatch("REQUEST_MY_PHOTOS")
    }
  },
  methods: {
    openPayment(data) {
      pushModal(PaymentModal, data)
    }
  }
}
</script>
  
<style scoped>
.me-wrp {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100%;
}

.btn {
  display: block;
  width: 60%;
  margin: .75rem auto;
}

.slider-wrp {
  max-width: 80%;
  margin: 0 auto;
}
</style>