const state = {
  bottomBarVisible: true,
};


const getters = {
  bottomBarVisible: state => state.bottomBarVisible,
};


const actions = {
  ["SHOW_BOTTOM_BAR"]: ({ state }) => {
    return new Promise(() => {
      state.bottomBarVisible = true
    })
  },
  ["HIDE_BOTTOM_BAR"]: ({ state }) => {
    return new Promise(() => {
      state.bottomBarVisible = false
    })
  },
  ["TOGGLE_BOTTOM_BAR"]: ({ state }, isVisible) => {
    return new Promise(() => {
      state.bottomBarVisible = isVisible
    })
  },
};

export default {
  state,
  getters,
  actions,
};