<template>
  <div class="game" :class="{mobile: isMobileDevice}">
    <template v-if="isMobileDevice">
      <MobileHeader disableBack>
        <template #controls-right>
          <button class="btn btn-transparent btn-filters" @click="openFilters">
            <i class="icon-filters"></i>
          </button>
        </template>
      </MobileHeader>
      <vue-bottom-sheet ref="searchBottomSheet">
        <FormSearch @search="onSearchChange" class="padding-h padding-t" />
      </vue-bottom-sheet>
    </template>

    <template v-else>
      <FormSearch @search="onSearchChange" class="padding-h padding-t" />
      <hr class="no-margin" />
    </template>
    


    <div class="profile pure-g container" ref="profileBlock">
      <VueElementLoading :active="isPageLoading" size="40" />
      <PreloaderAvatars :uids="uids" type="face_md" />
      <div class="pure-u-1 empty-block" v-if="isLoadedToEnd">
        <span class="block txt-c">
          К сожалению, по вашим параметрам мы не смогли найти ни одного пользователя.
          <br/><br/>
          Попробуйте изменить параметры и повторить поиск.
        </span>
      </div>

      <div class="photos-block pure-u-1" :class="{
        'pure-u-md-13-24': !isMobileDevice,
        'margin-b': !isMobileDevice
      }" v-if="user">
        <ProfilePhotos
          :uid="user.id"
          :gender="user.profile.gender"
          :photoCount="user.profile.photo_count"
          :bigPhotoHeight="profileHeightPercent"
          :showMiniatures="!isMobileDevice">
          <template #top="{ index }">
            <span class="photo-count" v-if="!isMobileDevice && user.profile.photo_count">
              <i class="icon-photo"></i>
              {{ index }}/{{ user.profile.photo_count }}
            </span>
          </template>

          <template #bottom="{ index }">
            <div class="game-bottom">
              <h1 class="name" :class="{ online: user.is_online }" v-if="isMobileDevice">
                {{ user.profile.name }},&nbsp;{{ user.profile.age }}
              </h1>
              <span class="city" v-if="isMobileDevice">
                <i class="icon-map-marker"></i> &nbsp;
                {{ city ? city.name : '.......' }}
                <VueElementLoading :active="city == null" spinner="bar-fade-scale" color="#BBB" size="20" />
              </span>

              <span class="photo-count" v-if="isMobileDevice && user.profile.photo_count">
                <i class="icon-photo"></i>
                {{ index }}/{{ user.profile.photo_count }}
              </span>
              <div class="game-actions">
                <button class="btn btn-skip" @click.stop="doSwipe(false)" ref="skipBtn">
                  <span>
                    <i class="icon-times"></i>
                  </span>
                </button>
                <router-link :to="{ name: 'Relations', params: { relationType: 'messages', uid: user.id } }" class="btn btn-message">
                  <i class="icon-msg"></i>
                </router-link>
                <button class="btn btn-like" @click.stop="doSwipe(true)" ref="likeBtn">
                  <span>
                    <i class="icon-like"></i>
                  </span>
                </button>
              </div>
              <router-link class="btn btn-profile" :to="{name: 'Profile', params: { uid: user.id }}" v-if="isMobileDevice">
                <i class="icon-profile"></i>
              </router-link>
            </div>
          </template>
        </ProfilePhotos>
      </div>

      <div class="pure-u-1 pure-u-md-11-24" v-if="user && !isMobileDevice">
        <div class="flex-row">
          <h1 class="flex-expand name" :class="{ online: user.is_online }">
            {{ user.profile.name }},&nbsp;{{ user.profile.age }}
          </h1>
          <MenuProfile :uid="user.id" @blacklisted="blacklistChanged" @favorited="favoriteChanged">
            <button class="btn btn-grey btn-more"><i class="icon-dots"></i></button>
          </MenuProfile>
        </div>

        <span class="city">
          <i class="icon-map-marker"></i> &nbsp;
          {{ city ? city.name : '.......' }}
          <VueElementLoading :active="city == null" spinner="bar-fade-scale" color="#BBB" size="20" />
        </span>

        <span class="last-online" v-if="lastOnline">{{ lastOnline }}</span>
        <span class="last-online online" v-else-if="user.is_online">Сейчас онлайн</span>

        <hr />

        <p v-if="user.profile.about_me">{{ user.profile.about_me }}</p>
        <ProfileProperties class="margin-v" :class="{ 'short-profile': !withProfile }" v-if="user"
          :properties="user.profile.properties" :gender="user.profile.gender" :showEmpty="true" />

        <div v-if="withProfile">
          <h2 class="margin-t">Интересы</h2>
          <ProfileInterests :uid="user.id" class="margin-half-v" />
        </div>

        <div class="txt-c margin-b" v-show="!withProfile">
          <button class="btn btn-grey-stroke" @click="openFullProfile">Показать профиль</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const format = require('string-format')
import moment from 'moment'

import LOCALIZATIONS from "@/localizations/ru"

import mixinDevice from "@/mixins/device.js"
import { pushModal } from "jenesius-vue-modal"

import { apiCall, SERVER_ADDRESSES } from "@/utils/api";
import { mapGetters } from "vuex";

import FormSearch from '@/components/FormSearch.vue'
import MenuProfile from '@/components/MenuProfile.vue'
import PreloaderAvatars from '@/components/PreloaderAvatars.vue'
import ProfilePhotos from '@/components/ProfilePhotos.vue'
import ProfileProperties from '@/components/ProfileProperties.vue'
import ProfileInterests from '@/components/ProfileInterests.vue'
import ConfirmAnything from '@/components/modals/ConfirmAnything.vue'

// mobile
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet"
import  "@webzlodimir/vue-bottom-sheet/dist/style.css";

import MobileHeader from '@/components/mobile/MobileHeader'

export default {
  name: 'Game',
  mixins: [mixinDevice],
  components: {
    FormSearch,
    MenuProfile, PreloaderAvatars,
    ProfilePhotos, ProfileProperties, ProfileInterests,
    // mobile
    VueBottomSheet, MobileHeader
  },
  data: function () {
    return {
      uids: [],
      uids_near_radius_step: 0,
      isLoadedToEnd: false,
      pagination: 0,
      pageSize: 20,
      users: [],
      avatars: {},
      withProfile: false,
      photos: null,
      isPageLoading: false,

      onlyOnline: false,
      onlyWithPhoto: false
    }
  },
  computed: {
    ...mapGetters(["cities"]),
    user: function () {
      return this.users.length ? this.users[0] : null
    },
    lastOnline() {
      if (!this.user || this.user.is_online) {
        return false
      }
      if (!this.user.last_online) {
        return 'Не в сети'
      }
      const text = LOCALIZATIONS['was_online_' + this.user.profile.gender]
      return format(text, moment.utc(this.user.last_online).local().calendar().toLowerCase())
    },
    city() {
      if (!this.user) {
        return null
      }
      if (!this.cities[this.user.profile.city_id]) {
        this.$store.dispatch("LOAD_CITIES", [this.user.profile.city_id])
        return null
      }
      return this.cities[this.user.profile.city_id]
    },
    profileHeightPercent() {
      if (!this.isMobileDevice) {
        return 120
      }
      const elem = this.$refs.profileBlock
      return (elem.offsetHeight+16*4)/(elem.offsetWidth)*100
    }
  },
  mounted: function () {
    this.loadPage()
  },
  methods: {
    loadPage() {
      this.isPageLoading = true
      this.loadPeople().then(users => {
        this.uids = users.map(u => u.id)
        this.$store.dispatch("LOAD_USERS", {uids: this.uids})
        this.$store.dispatch("LOAD_CITIES", users.map(u => u.profile.city_id))
        this.users = [...this.users, ...users]
        this.isPageLoading = false

        if (this.users.length === 0 && this.uids_near_radius_step === 0) {
          this.offerEnlargeRadius()        
        }
        if (!this.pagination && this.uids_near_radius_step > 0 && this.uids_near_radius_step < 5) {
          this.uids_near_radius_step++
        }
      })
    },
    async loadPeople() {
      let url = SERVER_ADDRESSES.users + "game?limit=" + this.pageSize
      if (this.pagination) {
        url += '&pagination=' + this.pagination
      }
      if (this.uids_near_radius_step) {
        url += '&radius_step=' + this.uids_near_radius_step
      }
      var resp = await apiCall({ url: url, method: "GET" })
      this.pagination = resp.pagination
      return resp.users
    },
    async loadProfile() {
      var scopes = ["base", "profile", "relation", "favorite"]
      var resp = await apiCall({ url: SERVER_ADDRESSES.users + this.user.id + "?scopes=" + scopes.join(','), method: "GET" })
      return resp.user
    },
    openFullProfile() {
      this.withProfile = true
      this.loadProfile().then((user) => {
        if (user.id != user.id) {
          return
        }
        this.users[0] = user
      })
    },
    blacklistChanged(isBlacklisted) {
      this.user.relations.is_blacklisted = isBlacklisted
    },
    favoriteChanged(isFavorite) {
      this.user.is_favorite = isFavorite
    },
    nextUser() {
      this.users.shift()
      this.withProfile = false

      if (this.users.length === 0 && this.uids_near_radius_step === 0) {
        this.offerEnlargeRadius()        
      } else if (this.users.length < 10 && !this.isPageLoading && this.pagination) {
        this.loadPage()
      }

      if (!this.pagination && this.uids_near_radius_step > 5) {
        this.isLoadedToEnd = true
      }
    },
    doSwipe(isLike) {
      const type = isLike ? 10 : -10,
        obj = isLike ? this.$refs.likeBtn : this.$refs.skipBtn

      obj.classList.add('animation');

      apiCall({ url: SERVER_ADDRESSES.users + this.user.id + '/swipe/' + type, method: "POST" })
      setTimeout(() => {
        this.nextUser()
        obj.classList.remove('animation')
      }, 300)
    },
    async offerEnlargeRadius() {
      const modal = await pushModal(ConfirmAnything, {
        title: "Расширить поиск",
        text: "На сегодня из вашего города анкеты закончились. Увеличить радиус поиска?",
        confirmText: "Да",
        denyText: "Нет"
      })
      modal.on('confirmed', () => {
        this.uids_near_radius_step++
        this.loadPage()
      })
    },
    openFilters() {
      if (this.$refs.searchBottomSheet) {
        this.$refs.searchBottomSheet.open()
      }
    },
    closeFilters() {
      if (this.$refs.searchBottomSheet) {
        this.$refs.searchBottomSheet.close()
      }
    },
    onSearchChange(e) {
      this.closeFilters()
      this.users = []
      this.pagination = null
      this.uids_near_radius_step = 0
      this.isLoadedToEnd = false
      this.withProfile = false

      this.onlyOnline = !!e.other.find(i => i == 'online')
      this.onlyWithPhoto = !!e.other.find(i => i == 'photo')
      this.loadPage()
    },
  }
}
</script>

<style>
.photos-block .photo {
  box-shadow: inset 0rem -12rem 10rem -9rem black;
}
</style>

<style scoped>
.empty-block {
  display: flex;
  min-height: 20rem;
  justify-content: center;
  align-items: center;
  color: #908f8f;
}

.profile {
  padding: 1rem;
}

.game.mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.game.mobile .profile,
.game.mobile .photos-block {
  height: 100%;
}

.game.mobile .game-bottom {
  width: 100%;
}
.game.mobile .game-bottom .game-actions {
  width: 13rem;
  margin: 1rem auto;
}

h1 {
  margin: 0 0 .2rem;
  font-size: 2rem;
  font-weight: 600;
}

h2 {
  margin: 0 0 .2rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.name.online:after {
  content: "";
  display: inline-block;
  width: .5rem;
  height: .5rem;
  margin: 0 0 .5rem .5rem;
  border-radius: 50%;
  background-color: #00d26d;
}

.city, .last-online {
  display: block;
  color: #908f8f;
  font-size: 1rem;
}
.city i {
  font-size: .8rem;
}

.game-bottom .city {
  color: #FFF;
  margin-bottom: 1rem;
}

.last-online {
  margin-top: .5rem;
}
.last-online.online {
  color: #359EFF;
}

.more-photos {
  position: relative;
}

.more-photos .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: .5rem;
  right: .5rem;
  height: 100%;
  border-radius: .4rem;
  background-color: rgba(0, 0, 0, .5);
}

.more-photos .overlay span {
  color: #FFF;
  font-weight: 700;
  font-size: 1.2rem;
}

button.like {
  transition: all .2s;
  color: #FF676C;
}

button.like i {
  position: relative;
  font-size: 1.2em;
  top: .15rem;
}

button.like.active {
  background-color: #FF676C;
  border-color: #FF676C;
  color: #FFF;
}

@keyframes game-btn-scale {
  from {
    transform: scale(1);
  }

  30% {
    transform: scale(1.25);
  }

  to {
    transform: scale(1);
  }
}

.btn.btn-like,
.btn.btn-skip,
.btn.btn-message,
.btn.btn-profile {
  color: #FFF;
  padding: .75rem 1.7rem;
  font-size: 1.2rem;
  border-radius: 50%;

  transition: none;
}

.btn.btn-message i {
  transition: none;
}

.game-actions,
.photo-count {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
}

.game-actions {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5rem;
  margin-bottom: 1rem;
}

.btn.btn-like:hover,
.btn.btn-skip:hover {
  background: rgba(0, 0, 0, .1);
}

.btn.btn-like {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #FF426F;
}

.btn.btn-skip {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn.btn-message {
  transform: scale(1.7);
  font-size: .9rem;
  padding: .5rem .7rem;
  margin: 0 .5rem;
  background-color: #359EFF;
  z-index: 2;
}

.btn.btn-message:hover {
  background-color: #FFF;
  color: #359EFF;
}

.btn.btn-like i,
.btn.btn-skip i {
  position: relative;
  top: .1em;
}

.btn.btn-like>span,
.btn.btn-skip>span {
  display: inline-block;
}

.btn.btn-like.animation span,
.btn.btn-skip.animation span {
  animation: game-btn-scale .3s ease-in-out;
}

.photo-count {
  display: inline-block;
  font-size: .8rem;
  padding: .5rem .5rem;
  border-radius: .25rem;
}

.photo-count i {
  font-size: .8em;
  margin-right: .33rem;
}

.game-bottom .photo-count,
.btn.btn-profile {
  position: absolute;
  bottom: .9rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.game-bottom .photo-count {
  left: -1rem;
  bottom: 1.6rem;
  background: none;
  -webkit-backdrop-filter: none;
    backdrop-filter: none;
}
.btn.btn-profile {
  right: 1%;
}

.short-profile {
  position: relative;
}

.short-profile:after {
  content: '';
  /* Выводим элемент */
  position: absolute;
  /* Абсолютное позиционирование */
  right: 0;
  bottom: 0;
  /* Положение элемента */
  width: 100%;
  /* Ширина градиента*/
  height: 140%;
  /* Высота родителя */
  /* Градиент */
  background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 0.0), #fff 100%);
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.0), #fff 100%);
  background: -o-linear-gradient(bottom, rgba(255, 255, 255, 0, 0.0), #fff 100%);
  background: -ms-linear-gradient(bottom, rgba(255, 255, 255, 0, 0.0), #fff 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), #fff 100%);
}

.btn-filters {
  border-radius: 0;
  color: #000;
}

.btn-filters i {
  position: relative;
  top: .2rem;
}
</style>