<template>
  <form class="pure-g container" @submit.prevent="submitPayment">
    <div class="pure-u-1 pure-u-md-12-24 pure-u-lg-10-24">
      <div class="pure-g container-sm period">
        <div class="pure-u-1-2 pure-u-md-1" v-for="p in payments" :key="'payment' + p.period">
          <label class="flex-row" @click="selectedPeriod = p.period"
            :class="{ active: p.period == payment.period }">
            <input type="radio" name="days" :value="p.period" :checked="p.period == payment.period" />
            
            <span class="flex-expand hidden-xs hidden-sm">
              {{ p.name }} - <strong>{{ p.price }}₽</strong>
            </span>
            <s class="hidden-xs hidden-sm">{{ p.striked }}₽</s>

            <span class="flex-expand hidden-md hidden-lg hidden-xl">
              <span class="block">{{ p.name }}</span>
              <s>{{ p.striked }}₽</s> <strong class="margin-l">{{ p.price }}₽</strong>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="pure-u-1 pure-u-md-12-24 pure-u-lg-14-24">
      <div class="bank-card">
        <label>Номер карты</label>
        <input type="text" v-model="card.number" />

        <div class="pure-g margin-t container">
          <div class="pure-u-8-24 pure-u-sm-6-24 pure-u-md-9-24 pure-u-lg-7-24">
            <label>Дата</label>
            <input type="text" v-model="card.expires" />
          </div>

          <div class="pure-u-8-24 pure-u-sm-6-24 pure-u-md-9-24 pure-u-lg-7-24">
            <label class="hidden-sm hidden-md hidden-lg hidden-xl">CVC</label>
            <label class="hidden-xs">CVC код</label>
            <input type="password" maxlength="3" v-model="card.cvc" />
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-xl btn-green full-width">Оплатить {{ payment.price }}₽</button>

      <p class="txt-c secured">
        <i class="icon-lock"></i> Безопасный платеж
      </p>

      <p class="txt-c">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor cididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostru
      </p>
    </div>
  </form>
</template>

<script>

import { popModal, pushModal } from "jenesius-vue-modal"
import { apiCall, SERVER_ADDRESSES } from "@/utils/api"

import { mapGetters } from "vuex";

import PaymentModalSuccess from "@/components/modals/payment/PaymentModalSuccess.vue"

export default {
  name: 'PaymentModalCard',
  props: {
    type: {  // manual, limit_swipes, limit_dialogs, push_top
      type: String,
      default: 'manual'
    },
  },
  data() {
    return {
      paymentsAll: [
        { name: 'День пробный', period: 1, price: 53, striked: 99},
        { name: 'Месяц', period: 30, price: 749, striked: 899},
        { name: 'Неделя', period: 7, price: 449, striked: 549},
        { name: '3 месяца', period: 90, price: 1449, striked: 2549},
        { name: 'Подняться в ТОП', period: 0, price: 49, striked: 99},
      ],
      selectedPeriod: -1,
      card: {
        number: '',
        expires: '',
        cvc: ''
      }
    }
  },
  computed: {
    ...mapGetters(["me"]),
    payments() {
      let payments = [...this.paymentsAll]
      if (this.me.premium.is_was_active) {
        payments = payments.filter(p => p.period != 1)
      }

      if (this.type == "push_top") {
        payments = payments.filter(p => p.visible = p.period == 0)
      } else {
        payments = payments.filter(p => p.visible = p.period != 0)
      }

      //this.payment = payments[0]
      return payments
    },
    payment() {
      if (this.selectedPeriod >= 0) {
        return this.payments.find(p => p.period == this.selectedPeriod)
      }
      return this.payments[0]
    }
  },
  methods: {
    async submitPayment() {
      const period = this.payment.period
      await apiCall({ url: SERVER_ADDRESSES.users + "me/premium/payment/" + period, method: "POST"})
      this.$store.dispatch("REQUEST_MY_PROFILE")
      this.$store.dispatch("LOAD_TOP_USERS", {force: true, cityId: this.me.profile.city_id})
      await popModal()
      await pushModal(PaymentModalSuccess, {days: period})
    }
  }
}
</script>

<style scoped>
.period label {
  cursor: pointer;
  padding: 1rem;
  border-radius: .4rem;
  border: 1px solid #DADADA;
  margin-bottom: 1rem;
}

.period label.active {
  border-color: #359EFF;
}

.period label input[type=radio] {
  width: auto;
  margin-right: 1rem;
}

.period s {
  color: #808080;
}

.period strong {
  color: #359EFF;
  font-size: 1.2rem;
}

.bank-card {
  margin-bottom: 2rem;
  padding: 1rem 1.2rem;
  color: #FFF;
  border-radius: .8rem;
  background-color: #359EFF;
  background: linear-gradient(66.18deg, #359EFF 0%, #2EE2EE 100%);
}

.bank-card input {
  border: none;
  color: #FFF;
  font-weight: 500;
  background-color: rgba(255, 255, 255, .2);
  font-size: 1.2rem;
  padding: 1rem 1rem;
}

.secured {
  color: #0FC257;
  font-size: 1.2rem;
  font-weight: 500;
}
</style>