<template>
  <div class="me txt-c">
    <router-link :to="{ name: 'ProfileMine' }" class="avatar" :class="{ 'avatar-premium': me && me.premium.is_active }">
      <Avatar :thumb="myAvatar ? myAvatar.thumbs.face_md : null" :hidden="false"
        :gender="me ? me.profile.gender : 'male'" :loading="myPhotos === null" :height="100" radius="50%" />
    </router-link>
    <router-link :to="{ name: 'ProfileMine' }" class="block name bigger margin-t">
      <span v-if="me">
        {{ me.profile.name }},&nbsp;{{ me.profile.age }}&nbsp;<i class="icon-pencil smaller" v-if="!isMobileDevice"></i>
      </span>
      <span v-else>&nbsp;&nbsp;</span>
    </router-link>
    <router-link :to="{ name: 'Rating' }" class="rating no-line">
      <RatingWidget :percent="me ? me.rating : 100" />
    </router-link>
  </div>
</template>


<script>
import { mapGetters } from "vuex"

import Avatar from '@/components/Avatar.vue'
import RatingWidget from '@/components/RatingWidget.vue'

import mixinDevice from "@/mixins/device.js"


export default {
  name: "AvatarWithRating",
  mixins: [mixinDevice],
  components: {
    Avatar, RatingWidget
  },
  computed: {
    ...mapGetters(["me", "myAvatar", "myPhotos"]),
  },
  mounted() {
    if (!this.me || !this.me.search) {
      this.$store.dispatch("REQUEST_MY_PROFILE")
    }
    if (this.myPhotos === null) {
      this.$store.dispatch("REQUEST_MY_PHOTOS")
    }
  },
}
</script>


<style scoped>
.me {
  padding: 1.5rem 0;
  line-height: 1.33;
}

.me .avatar {
  display: block;
  max-width: 9rem;
  margin: 0 auto 0 auto;
}

.me .avatar.avatar-premium {
  position: relative;
  padding: .25rem;
  border-radius: 50%;
  box-sizing: border-box;
}

.me .avatar.avatar-premium::before {
  position: absolute;
  display: block;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  content: "";
  background: linear-gradient(-45deg, #FF426F, #359EFF);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}

.me .avatar.avatar-premium::after {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

.me .avatar.avatar-premium .avatar {
  z-index: 1;
}

.me a i {
  transition: color .2s ease-in-out;
}

.me a:hover i {
  color: #222020;
}

.name,
.name:active,
.name:visited,
.name:hover {
  font-weight: 600;
  text-decoration: none;
  color: #222020;
}

.rating,
.rating:active,
.rating:visited {
  display: block;
  text-decoration: none;
  color: #222020;
}

.name i {
  color: #bdbcbc;
}
</style>