<template>
  <div class="pure-g bar" v-show="bottomBarVisible">
    <router-link class="pure-u-1-4 bar__link" :to="{ name: 'Near' }">
      <i class="icon-menu-search"></i>
      Поиск
    </router-link>
    <router-link class="pure-u-1-4 bar__link" :to="{ name: 'Game' }">
      <i class="icon-menu-game"></i>
      Игра
    </router-link>
    <router-link class="pure-u-1-4 bar__link" :to="{ name: 'Me' }">
      <i class="icon-profile"></i>
      Профиль
    </router-link>
    <router-link class="pure-u-1-4 bar__link" :to="{ name: 'Relations', params: { relationType: 'messages' } }">
      <i class="icon-menu-msg"></i>
      Сообщения
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'BottomNavBar',
  computed: {
    ...mapGetters(["bottomBarVisible"]),
  }  
}
</script>


<style scoped>
.bar {
  height: var(--bottom-bar-height);
  background-color: #FFF;
  z-index: 1;
  box-shadow: 0px -2px 10px 0px #0000000D;
}

.bar__link, .bar__link:active, .bar__link:visited {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: .7rem;
  padding: .75rem 0 .5rem;
  color: #D3D3D3;
}

.bar__link i {
  display: block;
  margin-bottom: .5rem;
  font-size: 1.5rem;
}

.router-link-active.bar__link {
  color: #FF426F;
}
</style>
