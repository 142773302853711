import { createStore } from 'vuex'

import auth from "./modules/auth";
import cities from "./modules/cities";
import users from "./modules/users";
import dialogs from "./modules/dialogs";

import system from "./modules/system";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    cities,
    users,
    dialogs,
    system
  }
})
