<template>
  <router-link class="dialog flex-row block-vcenter" :to="{ name: 'Messages', params: { uid: dialog.to_id } }">
    <span class="avatar-wrp margin-half-r">
      <ProfileCircle class="avatar full-width" :uid="dialog.to_id" />
    </span>
    <span class="flex-expand">
      <span v-if="user" class="name">{{ user.profile.name }}</span>

      <span class="last-msg typing" v-if="typing">Печатает...</span>
      <span class="last-msg" v-else-if="lastMsg && lastMsg.text">{{ lastMsg.text }}</span>
      <span class="last-msg" v-else-if="lastMsg && lastMsg.has_attachments">Фотография</span>
      <span class="last-msg" v-else>&nbsp;</span>
    </span>
    <span class="txt-r">
      <span class="status" v-if="lastMsg && lastMsg.from_id == myId">
        <i v-if="isPartnerRead" class="icon-check-double"></i>
        <i v-else-if="lastMsg.date" class="icon-check"></i>
      </span>
      <span class="date">{{ dateAsString }}</span>
      <br />
      <span v-if="dialog.unread_count" class="unread">{{ dialog.unread_count }}</span>
      <span v-else>&nbsp;</span>
    </span>
  </router-link>
</template>

<script>
import moment from 'moment'
import { mapGetters } from "vuex";

import ProfileCircle from '@/components/ProfileCircle'

export default {
  name: 'DialogEntry',
  props: {
    "dialog": {
      required: true
    },
    "typing": {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProfileCircle
  },
  data() {
    return {
      typingTimeout: null,
    }
  },
  computed: {
    ...mapGetters(["myId", "usersStore", "avatarsStore"]),
    user() {
      return this.dialog.to_id in this.usersStore ? this.usersStore[this.dialog.to_id] : null
    },
    avatar() {
      return this.dialog.to_id in this.avatarsStore ? this.avatarsStore[this.dialog.to_id] : null
    },
    lastMsg() {
      return this.dialog.last_msg ? this.dialog.last_msg : null
    },
    dateAsString() {
      if (this.lastMsg && this.lastMsg.date) {
        return moment.utc(this.lastMsg.date).local().format('LT')
      }
      return this.dialog.date ? moment.utc(this.dialog.date).local().format('LT') : ''
    },
    isPartnerRead() {
      if (!this.lastMsg || !this.lastMsg.date || !this.dialog.partner_read_until) {
        return false
      }
      return new Date(this.dialog.partner_read_until) >= new Date(this.lastMsg.date)
    }
  },
}
</script>

<style scoped>
.dialog,
.dialog:active,
.dialog:visited {
  padding: .5rem 1rem;
  text-decoration: none;
  color: #222020;
  transition: all .2s ease-in-out;
  outline: none;
}

.dialog:hover,
.dialog:active,
.dialog:focus {
  background-color: #FAFAFA;
}

.dialog.router-link-active,
.dialog.router-link-active:active,
.dialog.router-link-active:visited,
.dialog.router-link-active:hover {
  background-color: #359EFF;
  color: #FFF;
}

.avatar-wrp {
  min-width: 3.2rem;
  width: 3.2rem;
  margin: .25rem 1rem .25rem 0;
  display: flex;
}

.avatar-wrp .avatar {
  align-self: end;
}

.dialog .name {
  display: block;
  margin: 0 0 .25rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.dialog .last-msg {
  display: block;
  font-size: .9rem;
  max-height: 1.2em;
  overflow: hidden;
  line-height: 1.1em;
}

.dialog .last-msg,
.dialog .date {
  color: #646363;
}

.dialog .last-msg.typing {
  color: #359EFF;
}

.dialog .status {
  font-size: .8rem;
  color: #359EFF;
  margin: 0 .5rem;
}

.dialog .status .icon-check {
  font-size: .8em;
}

.dialog.router-link-active .last-msg,
.dialog.router-link-active .last-msg.typing,
.dialog.router-link-active .date,
.dialog.router-link-active .status {
  color: #FFF;
}

.dialog .unread {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #FF426F;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>