<template>
  <div>
    <MobileHeader title="Редактирование" defaultBackUrl="/me" v-if="isMobileDevice" />
    <div class="profile pure-g container">
      <div class="pure-u-1 pure-u-md-13-24">
        <ProfilePhotos
          :uid="myId"
          :gender="me ? me.profile.gender : 'male'"
          :photoCount="me ? me.profile.photo_count : null"
          :editing="true"
          :mode="isMobileDevice ? 'six' : 'single'" />

        <button class="btn btn-grey-stroke full-width margin-t" type="button" @click="uploadPhotos" v-if="!isMobileDevice">
          <i class="icon-photo-add margin-half-r"></i> Добавить фото
        </button>

        <div class="property flex-row">
          <div class="flex-expand">
            <strong>Скрыть фотографии</strong>
            <span>Ваши фотографии смогут увидеть только те, кому вы напишите сообщение или поставите лайк</span>
          </div>
          <Toggle class="margin-l" v-model="isPhotosHidden" @change="setPhotosHidden" />
        </div>
      </div>
      
      <div class="pure-u-1 pure-u-md-11-24 margin-t" v-if="me">
        <div v-if="!isEditingBaseData">
          <div class="flex-row block-vcenter">
            <h1 class="flex-expand name" :class="{online: me.is_online}">
              {{ me.profile.name }},&nbsp;{{ me.profile.age}}
            </h1>
            <button class="btn btn-grey edit-btn btn-square no-margin" v-show="!isEditingBaseData" @click="isEditingBaseData = true"><i class="icon-pencil-o"></i></button>
          </div>

          <span class="city">
            <i class="icon-map-marker"></i> &nbsp;
            {{ city ? city.name : '.......' }}
            <VueElementLoading :active="city == null" spinner="bar-fade-scale" color="#BBB" size="20"/>
          </span>
        </div>
        <ProfileEditBase v-else :user="me" @cancel="isEditingBaseData = false" @confirm="updateProfileBase" />
        
        <hr/>

        <button class="btn btn-grey edit-btn btn-square no-margin margin-half-t pull-right" v-show="!isEditingAboutMe" @click="isEditingAboutMe = true">
          <i class="icon-pencil-o"></i>
        </button>
        <p class="aboutme nl2br" v-if="!isEditingAboutMe" @click="isEditingAboutMe = true">
          <span v-if="me.profile.about_me">{{ me.profile.about_me }}</span>
          <span class="empty" v-else>Расскажите о себе</span>
        </p>
        <div class="pure-g container-sm" v-else>
          <div class="pure-u-1 margin-b">
            <textarea type="text" v-model="about_me" placeholder="Расскажите о себе" rows="6"></textarea>
          </div>
          <div class="pure-u-1-3">
            <button class="btn btn-grey full-width" @click="isEditingAboutMe = false">Назад</button>
          </div>
          <div class="pure-u-2-3 form-field">
            <button class="btn btn-blue full-width" @click="updateAboutMe">Сохранить</button>
          </div>
        </div>
        

        <div class="flex-row margin-t">
          <h2 class="flex-expand">Дополнительно</h2>
          <button class="btn btn-link edit-btn" v-show="!isEditingProperties" @click="isEditingProperties = true">Редактировать</button>
          <button class="btn btn-link edit-btn" v-show="isEditingProperties" @click="isEditingProperties = false">Завершить</button>
        </div>
        <ProfileProperties class="margin-v" v-if="me && !isEditingProperties" :properties="me.profile.properties" :gender="me.profile.gender" :showEmpty="true" />
        <ProfileEditProperties class="margin-v" v-else-if="me && isEditingProperties" :properties="me.profile.properties" :gender="me.profile.gender" @update:property="onPropertyUpdated" />

        <hr class="margin-v" />
        <div class="flex-row margin-t">
          <h2 class="flex-expand">Интересы</h2>
          <button class="btn btn-link edit-btn" v-show="!isEditingInterests" @click="isEditingInterests = true">Редактировать</button>
          <button class="btn btn-link edit-btn" v-show="isEditingInterests" @click="isEditingInterests = false">Завершить</button>
        </div>
        <ProfileInterests :uid="myId" :editing="isEditingInterests" />
      </div>
    </div>
  </div>
</template>

<script>
import {pushModal} from "jenesius-vue-modal"
import { mapGetters } from "vuex";

import Toggle from '@vueform/toggle'
import '@vueform/toggle/themes/default.css'

import ProfilePhotos from '@/components/ProfilePhotos.vue'
import ProfileProperties from '@/components/ProfileProperties.vue'
import ProfileInterests from '@/components/ProfileInterests.vue'

import ProfileEditBase from '@/components/ProfileEditBase.vue'
import ProfileEditProperties from '@/components/ProfileEditProperties.vue'

import UploadPhotos from '@/components/modals/UploadPhotos.vue'
import ConfirmAnything from '@/components/modals/ConfirmAnything.vue'

// mobile
import mixinDevice from "@/mixins/device.js"
import MobileHeader from '@/components/mobile/MobileHeader'

export default {
  mixins: [mixinDevice],
  components: {
    Toggle,
    ProfilePhotos, ProfileProperties, ProfileInterests,
    ProfileEditBase, ProfileEditProperties,

    // mobile
    MobileHeader
  },
  data: function() {
    return {
      isEditingBaseData: false,
      isEditingAboutMe: false,
      isEditingProperties: false,
      isEditingInterests: false,

      isPhotosHidden: false,

      about_me: ""
    }
  },
  computed: {
    ...mapGetters(["myId", "me", "cities", "topUsersStore"]),
    city: function() {
      if (!this.me) {
        return null
      }
      if (!this.cities[this.me.profile.city_id]) {
        this.$store.dispatch("LOAD_CITIES", [this.me.profile.city_id])
        return null
      }
      return this.cities[this.me.profile.city_id]
    },
    isMeInTopUsers() {
      return this.topUsersStore.length && this.topUsersStore.indexOf(this.myId) !== -1
    }
  },
  watch: {
    me() {
      this.about_me = this.me.profile.about_me
      this.isPhotosHidden = this.me.privacy.hide_photos
    }
  },
  mounted: function() {
    this.$store.dispatch("HIDE_BOTTOM_BAR")
    if (this.me) {
      this.about_me = this.me.profile.about_me
      this.isPhotosHidden = this.me.privacy.hide_photos
    } else {
      this.$store.dispatch("REQUEST_MY_PROFILE")
    }
  },
  methods: {
    updateProfileBase(data) {
      this.$store.dispatch("UPDATE_PROFILE", data)
      this.isEditingBaseData = false
    },
    updateAboutMe() {
      this.$store.dispatch("UPDATE_PROFILE", {
        about_me: this.about_me
      })
      this.isEditingAboutMe = false
    },
    onPropertyUpdated(data) {
      this.$store.dispatch("UPDATE_PROFILE_PROPERTIES", data)
    },
    uploadPhotos() {
      pushModal(UploadPhotos, {
        uid: this.myId,
      })
    },
    async setPhotosHidden() {
      if (this.isMeInTopUsers && this.isPhotosHidden) {
        this.isPhotosHidden = false
        const modal = await pushModal(ConfirmAnything, {
          title: "Вы уверены?",
          text: "<b>Внимание!</b> Вы оплатили поднятие анкеты и сейчас находитесь в ТОПе. Если вы скроете свои фотографии, то мы не сможем показывать вас там.",
          confirmText: "Скрыть",
          denyText: "Отмена"
        })
        modal.on('confirmed', () => {
          this.isPhotosHidden = true
          this.$store.dispatch("UPDATE_PRIVACY", {data: {hide_photos: this.isPhotosHidden} })
        })
        return
      }
      this.$store.dispatch("UPDATE_PRIVACY", {data: {hide_photos: this.isPhotosHidden} })
    }
  }
}
</script>


<style scoped>
.profile {
  padding: 1rem 1rem 0;
}

.mobile .profile {
  padding: 1rem;
}

h1 {
  margin: 0 0 .2rem;
  font-size: 2rem;
  font-weight: 600;
}

h2 {
  margin: -.04rem 0 0;
  font-size: 1.4rem;
  font-weight: 600;
}

.aboutme {
  margin-left: -.5rem;
  padding: .5rem .5rem;
  transition: all .2s ease-in-out;
  cursor: pointer;
}
.aboutme .empty {
  color: #AAA;
}
.aboutme:hover {
  background-color: #F8F8F8;
}

.name.online:after {
  content: "";
  display: inline-block;
  width: .5rem;
  height: .5rem;
  margin: 0 0 .5rem .5rem;
  border-radius: 50%;
  background-color: #00d26d;
}

.city {
  color: #908f8f;
  font-size: 1rem;
}
.city i {
  font-size: .8rem;
}

.more-photos {
  position: relative;
}
.more-photos .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: .5rem;
  right: .5rem;
  height: 100%;
  border-radius: .4rem;
  background-color: rgba(0, 0, 0, .5);
}
.more-photos .overlay span {
  color: #FFF;
  font-weight: 700;
  font-size: 1.2rem;
}

.edit-btn {
  position: relative;
  padding: .5rem;
  margin-top: -.5rem;
}

.edit-btn.btn-square {
  height: 2rem;
  width: 2rem;
}

.edit-btn i {
  position: relative;
  top: -.1rem;
}

.property {
  margin: 1rem 0;
  align-items: center;
}
.property strong {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  color: #222020;
}
.property span {
  display: block;
  color: #908f8f;
  margin: .25rem 0;
  font-weight: 400;
  font-size: .8rem;
}
</style>